const ConfigUrl = "https://fhtconfig.s3.ap-southeast-2.amazonaws.com/SiteSpecificConfig.jsonc";

var SiteSpecificConfig = {
     "SiteConfigurationMappingKeys": {
          "serverapi": "serverapi", "Batch": "Batch",
     },
     "BatchSiteConfigurationMapping": {
          "fhtProduction": { "IsTorch": "false", "fhtTrialNews": "false","ADMedicationUsualDoctorPrescriberNo": "false", "APILoggingCSVDownload": "false", "PatientDetailPregnancyStatus": "false", "CohortRecallDefer": "true" },
          "developer": { "IsTorch": "false", "fhtTrialNews": "false","ADMedicationUsualDoctorPrescriberNo": "false", "APILoggingCSVDownload": "false", "PatientDetailPregnancyStatus": "false", "CohortRecallDefer": "true" },
          "fhttestsites": { "IsTorch": "false", "fhtTrialNews": "false", "ADMedicationUsualDoctorPrescriberNo": "false", "APILoggingCSVDownload": "false", "PatientDetailPregnancyStatus": "false", "CohortRecallDefer": "true" }, // grhanite-zedmed, grhanite-fhtzedmed, grhanite-genie machine
          "fhtUAT": { "IsTorch": "false", "fhtTrialNews": "false", "ADMedicationUsualDoctorPrescriberNo": "false", "APILoggingCSVDownload": "false", "PatientDetailPregnancyStatus": "false", "CohortRecallDefer": "true" }, // grhanite-demo
          "fhtsimlabsites": { "IsTorch": "false", "fhtTrialNews": "false", "ADMedicationUsualDoctorPrescriberNo": "false", "APILoggingCSVDownload": "false", "PatientDetailPregnancyStatus": "false", "CohortRecallDefer": "false" }, 
     },
     //The goal is to enable/disable what algorithms a site sees without doing a database update
     "AlgorithmSiteMapping": {
          "normalSite": {
               "CreateCohortCodedDiagnosisUI": "13,14,23", "CreateCohortDropDownDiseaseUI": "1,2,4",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18, \
                                        19,20,21,22,23,24,25,26,27,28, \
                                        4000,4001,4002,4003,4004, \
                                        6000,6001,6002,6003,6004,6005,6006,6008,6010, \
                                        9000,9001,9002,9003,9004,9005,9006,9009,9013, \
                                        "},

          //FHT program sites
          "FHTProgramUpdated": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018"
          },          

          //FHT program with Simply B
          "FHTProgramdwithSimplyB": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,20",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,22001"
          },

          //FHT program with Simply B and WH
          "FHTProgramdwithSimplyBandWH": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,20",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,40,41,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,22001"
          },

          //FHT program with UWL 
          "FHTProgramwithUWL": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,23",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,114000,114001,114002,114003,114004,114005,114006"
          },

          //FHT program with UWL and WH
          "FHTProgramwithUWLandWH": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,23",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,40,41,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,114000,114001,114002,114003,114004,114005,114006"
          },

          //FHT proagm with Good4Mum
          "FHTProgramwithGood4Mum": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,27",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,26000,26001,26002"
          },

          //FHT program with Good4Mum and Canary 
          "FHTProgramwithGood4MumandCanary": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,23,27",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,26000,26001,26002,114000,114001,114002,114003,114004,114005,114006"
          }, 

          //FHT program with Good4Mum and Canary and WH
          "FHTProgramwithGood4MumandCanaryandWH": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,23,27",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,40,41,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,26000,26001,26002,114000,114001,114002,114003,114004,114005,114006"
          }, 


          //FHT program with Good4Mum and Canary and WH and Meno
          "FHTProgramwithGood4MumandCanaryandWHandMeno": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,23,27,34",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,40,41,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,26000,26001,26002,114000,114001,114002,114003,114004,114005,114006,33000,33001,33002"
          },           

          //Only Meno
          "FHTMeno": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "34",
               "FhtEnabledAnalysisTestIds": "33000,33001,33002"
          },               

          //FHT program with Good4Mum and WH
          "FHTProgramwithGood4MumandWH": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,27",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,40,41,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,26000,26001,26002"
          },

          //FHT program with Good4Mum and WH
          "FHTProgramwithGood4MumandWHandMeno": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,27,34",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,40,41,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,26000,26001,26002,33000,33001,33002"
          },

          //FHTMDsites with UWL and Ideal 
          "FHTProgramwithUWLAndIdealandWH": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,23,25",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,40,41,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,114000,114001,114002,114003,114004,114005,114006,24002,24003"
          },

          //FHT program site only have Syphilis algorithms
          "FHTProgramSiteWithSyphilis": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "18",
               "FhtEnabledAnalysisTestIds": "16000,16001"
          },

          //FHTACTMed sites Cathy
          "FHTACTMED": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "2",
               "FhtEnabledAnalysisTestIds": "6004,6013,6008,6014,6024"
          },

          //FHTACTMed sites CVD 4.8/11/12/13/17 + ACTMED type diabetes 6.1-6.4 
          "FHTACTMED3": {
               "CreateCohortCodedDiagnosisUI": "13,14,21,23,9006,9013", "CreateCohortDropDownDiseaseUI": "2,21",
               "FhtEnabledAnalysisTestIds": "6004,6013,6008,6014,6024,20000,20001,20002,20003"
          },

          //FHT program & ACTMed - display CKD, CVD, T2D, Cancer, WH 
          "FHTprogramWH": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,40,41,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018"
          },
          
          //FHT base modules  - display CKD, CVD, T2D, Cancer
          "FHTBase": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018"
          },
     

          //FHT program & ACTMed - display CKD, CVD, T2D, Cancer, WH and Meno 
          "FHTprogramWHandMeno": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,34",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,19,20,21,22,24,25,26,27,28,40,41,4000,4001,4002,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,33000,33001,33002"
          },
     
          //FHT WH program sites but NOT display Cancer
          "FHTWH": {
               "CreateCohortCodedDiagnosisUI": "13,14,21,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3",
               "FhtEnabledAnalysisTestIds": "40,41,1,2,3,4,5,6,7,8,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018"
          },

          //FHT WH sites display CKD WH algo only
          "FHTWHOnly": {
               "CreateCohortCodedDiagnosisUI": "13,14,21,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1",
               "FhtEnabledAnalysisTestIds": "40,41"
          },


          //FHT GooD4Mum sites display GooD4Mum algo only
          "FHTGooD4Mum": {
               "CreateCohortCodedDiagnosisUI": "13,14,21,23,9006,9013", "CreateCohortDropDownDiseaseUI": "27",
               "FhtEnabledAnalysisTestIds": "26000,26001,26002"
          },

          //FHT sites display WH and GooD4Mum algo 
          "FHTWHandGood4Mum": {
               "CreateCohortCodedDiagnosisUI": "13,14,21,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,27",
               "FhtEnabledAnalysisTestIds": "40,41,1,2,3,4,5,6,7,8,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028,9000,9001,9002,9003,9004,9005,9018,26000,26001,26002"
          },


          //FHT unimelbsimsti (display 2 Syphilis  recommendation)
          "FHTSyphilisSite": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "18",
               "FhtEnabledAnalysisTestIds": "16000, 16001"
          },
          "FHTDirectT1DMSite": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "50",
               "FhtEnabledAnalysisTestIds": "50000, 50001"
          },
          "FHTLowBackPainSite": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "26",
               "FhtEnabledAnalysisTestIds": "25000"
          },
          "FHTCanary": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "23",
               "FhtEnabledAnalysisTestIds": "114000,114001,114002,114003,114004,114005,114006"
          },
          "FHTPancreaticCancerSite": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "32",
               "FhtEnabledAnalysisTestIds": "31000, 31001, 31004"
          },

          "zedmedSite": {
               "CreateCohortCodedDiagnosisUI": "13,14", "CreateCohortDropDownDiseaseUI": "1,2",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18, \
                                        4000,4001,4002,4003,4004, \
                                        6000,6001,6002,6003,6004,6005,6006,6008,6010, \
                                        9000,9001,9002,9003,9004,9005,9006,9009,9013, \
                                        "},
          "adwiserSite": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,6",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18, \
                                        19,20,21,22,23,24,25,26,27,28, \
                                        4000,4001,4002,4003,4004, \
                                        6000,6001,6002,6003,6004,6005,6006,6008,6010, \
                                        9000,9001,9002,9003,9004,9005,9006,9009,9013, \
                                        "},
          "zedmedAdwiserSite": {
               "CreateCohortCodedDiagnosisUI": "13,14,9006,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,6",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18, \
                                        4000,4001,4002,4003,4004, \
                                        6000,6001,6002,6003,6004,6005,6006,6008,6010, \
                                        9000,9001,9002,9003,9004,9005,9006,9009,9013, \
                                        "},
          "testSite": {
               "CreateCohortCodedDiagnosisUI": "13,14,17,23,6010,9006,9009,9013,9018", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,18,20,21,22,23,25,26,27",
               "FhtEnabledAnalysisTestIds": "2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18, \
                                        19,20,21,22,23,24,25,26,27,28,40,41,45, \
                                        4000,4001,4002,4003,4004, \
                                        6000,6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028, \
                                        7001,7002,7003,7004,7005, \
                                        8001,8002, \
                                        9000,9001,9002,9003,9004,9005,9006,9009,9013,9018, \
                                        16000,16001, \
                                        22001, \
                                        23000,23001, \
                                        20000,20001,20002,20003, \
                                        114000,114001,114002,114003,114004,114005,114006, \
                                        24002,24003, \
                                        25000, \
                                        26000,26001,26002, \
                                        "},
          "testSitefordemo": {
               "CreateCohortCodedDiagnosisUI": "13,14,17,23,6010,9006,9009,9013,9018", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,18,20,21,22,23,25,26,27,32,34",
               "FhtEnabledAnalysisTestIds": "2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18, \
                                        19,20,21,22,23,24,25,26,27,28,40,41,45, \
                                        4000,4001,4002,4003,4004, \
                                        6001,6002,6003,6004,6005,6006,6008,6013,6014,6015,6018,6020,6021,6024,6025,6027,6028, \
                                        7001,7002,7003,7004,7005, \
                                        8001,8002, \
                                        9000,9001,9002,9003,9004,9005,9006,9009,9013,9018, \
                                        16000,16001, \
                                        22001, \
                                        23000,23001, \
                                        20000,20001,20002,20003, \
                                        114000,114001,114002,114003,114004,114005,114006, \
                                        24002,24003, \
                                        25000, \
                                        26000,26001,26002, \
                                        31000,31001,31004,\
                                         33000,33001,33002, \
                                        "},
          "ichampsite": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,6010,9006,9009,9013", "CreateCohortDropDownDiseaseUI": "1,2,3,4,5,6,8",
               "FhtEnabledAnalysisTestIds": "1,2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18, \
                                   19,20,21,22,23,24,25,26,27,28, \
                                   4000,4001,4002,4003,4004, \
                                   6000,6001,6002,6003,6004,6005,6006,6008,6010,6013,6014,6015,6020,6021,6028, \
                                   7001,7002,7003,7004,7005, \
                                   8001,8002, \
                                   9000,9001,9002,9003,9004,9005,9006,9009,9013,9018, \
                                   "},
          "demoTorchWiserADAndMindEnabledTest": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "7,10,11,12,13,14,15,16,17,19",
               "FhtEnabledAnalysisTestIds": "5002,5003,11000,12000,13000,13001,14000,15000,17000,17001,17002,19000"
          },
          "zedmedTorchWiserADAndMindEnabledTest": {
               "CreateCohortCodedDiagnosisUI": "13,14,23,9006,9013", "CreateCohortDropDownDiseaseUI": "7,10,11,12,13,14,15,17,19",
               "FhtEnabledAnalysisTestIds": "5002,5003,11000,12000,13000,13001,14000,15000,17000,17001,17002,19000"
          },
     },
     "SiteConfigurationMapping": {
          //local testing only
          //"localhost:": {"serverapi":"https://api.futurehealthtoday.com.au", "Batch":"milestone0TestSites", "AlgorithmType":"zedmedSite", "RecommendationAction": "false"}, //change the serverapi to suit your developer needs.
          //"localhost": { "serverapi": "http://localhost:57502", "Batch": "torchrecruitProduction", "AlgorithmType": "trialSiteC", "analyticsid": "G-WXK3YG70F2" }, //change the serverapi to suit your developer needs.
          "localhost": { "serverapi": "http://localhost:57502", "Batch": "developer", "AlgorithmType": "testSite", "analyticsid": "G-WXK3YG70F2" }, //change the serverapi to suit your developer needs.
          //"localhost": {"serverapi":"http://localhost:57502", "Batch":"torchdeveloper","AlgorithmType":"demoTorchWiserADAndMindEnabledTest"}, //change the serverapi to suit your developer needs.
          //"localhost:": {"serverapi":"http://api.logger.futurehealthtoday.com.au", "Batch":"milestone0TestSites", "AlgorithmType":"zedmedSite", "RecommendationAction": "false"}, //change the serverapi to suit your developer needs.
          //production
          //FHT withdraw 2023-02-06 "anmg.futurehealthtoday.com.au":{"serverapi":"https://FHTServer:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTCKDSite", "analyticsid": "G-15K3DZ6RFV"},
          "smc.futurehealthtoday.com.au": { "serverapi": "https://SMC-GRHANITE:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-5Q9Q248BFB" },
          //withdraw FHT on 2023-02-08 "coolaroo.futurehealthtoday.com.au":{"serverapi":"https://COO-WKS23:57503",        "Batch":"fhtProduction", "AlgorithmType":"FHTCKDSite", "analyticsid": "G-CJ7N318Z2N"},
          "myclinictarneit.futurehealthtoday.com.au": { "serverapi": "http://MCTPC05:57502", "Batch": "fhtProduction", "AlgorithmType": "normalSite", "analyticsid": "G-EZ55F5DKSF" },
          "ghwangaratta.futurehealthtoday.com.au": { "serverapi": "https://GHWNGFHT01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-6G634BMTW7" },
          "tmc.futurehealthtoday.com.au": { "serverapi": "https://TMC-DC:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithUWLAndIdealandWH", "analyticsid": "G-X3PRJ7MW18" },
          "deepdenesurgery.futurehealthtoday.com.au": { "serverapi": "https://PM:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWHandMeno", "analyticsid": "G-TK5E99BGD5" },
          "siamooneeponds.futurehealthtoday.com.au": { "serverapi": "https://SMP-DT-RSLTS:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-HRQQ4SHCBG" },
          "theclinicsunshine.futurehealthtoday.com.au": { "serverapi": "http://Admin1:57502", "Batch": "fhtProduction", "AlgorithmType": "normalSite", "analyticsid": "G-LFD62E0NMG" },
          "dsgmc.futurehealthtoday.com.au": { "serverapi": "https://SWMC-DT-RSLTS:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-58JJX31JD4" },

          //New FHT program sites:
          //withdraw FHT on 20240524 "ferenlisikgp.futurehealthtoday.com.au": { "serverapi": "https://FALS-TS01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramUpdated", "analyticsid": "G-F9E9P834C4" },
          "kardiniahealth.futurehealthtoday.com.au": { "serverapi": "https://KDH-VM-WIN10:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithGood4MumandCanaryandWHandMeno", "analyticsid": "G-DCTL9S9XN4" },
          "utopiarefugeehealth.futurehealthtoday.com.au": { "serverapi": "https://UTOP-TS01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-M1NW5VQ11B" },
          //withdraw FHT on 10-07 "parkvilleprecinct.futurehealthtoday.com.au": { "serverapi": "https://FHTSERVER:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-C3GDT87EX5" },
          //withdraw "tristarmedicalgroup.futurehealthtoday.com.au":{"serverapi":"https://FHTSERVER:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTprogSiteUpdated", "analyticsid": "G-D484C9XGZV"},

          //New FHT trial sites:
          //some program sites set as a trial (testing purposes)

          "aimhealth.futurehealthtoday.com.au": { "serverapi": "https://server-2018:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-GE7GLG5X8S" },
          "alsalammc.futurehealthtoday.com.au": { "serverapi": "https://FHT-VM:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithGood4MumandWH", "analyticsid": "G-6MWR252RDH" },
          "bendigoprimarycare.futurehealthtoday.com.au": { "serverapi": "https://BPCC01-PATH01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-3XECHRPH54" },
          "botanicalgardenshealth.futurehealthtoday.com.au": { "serverapi": "https://WKSBGH01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-RCYK8XHFL6" },
          "charltonmedical.futurehealthtoday.com.au": { "serverapi": "https://EWHSCHAWKS1924:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithUWLandWH", "analyticsid": "G-CB3FCP8MG5" },
          // withdraw FHT on 20/10 "croydonmedicalcentre.futurehealthtoday.com.au":{"serverapi":"https://SPARE-PC:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTprogSiteUpdated", "analyticsid": "G-DX8RZ04DBJ"},
          "euroamedical.futurehealthtoday.com.au": { "serverapi": "https://EMFPD55:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-7D0KNLQ21P" },
          "familycaremedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://FCMCDT02:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-S497YC9WC1" },
          "forsythpark.futurehealthtoday.com.au": { "serverapi": "https://WKS03:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-9PRTN52B4H" },
          "gatewayhealthwodonga.futurehealthtoday.com.au": { "serverapi": "https://GHWODFHT01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-0H4EJ8ENP2" },
          "hillcresthealthcentre.futurehealthtoday.com.au": { "serverapi": "https://HHCPC16:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-GBWVV13JR5" },
          "innovationhealthgroup.futurehealthtoday.com.au": { "serverapi": "https://wks-05:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-5E00YMQY8Q" },
          // withdraw FHT on 20/10 "kerrieroadfamilymc.futurehealthtoday.com.au":{"serverapi":"https://KRRDS:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTprogSiteUpdated", "analyticsid": "G-P4NCGG32CJ"},
          "lincolnvillemedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://MANAGEMENT:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithGood4MumandWH", "analyticsid": "G-Q0QD1RVTRT" },
          "maidstonefamilyclinic.futurehealthtoday.com.au": { "serverapi": "https://SERVER02:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-L6SW7Y0VCS" },
          //withdraw FHT on 13/06/2024 "mediscreenclinic.futurehealthtoday.com.au": { "serverapi": "https://ARGYROPC:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramUpdated", "analyticsid": "G-VN8JW659J1" },
          //withdraw FHT on 2023-01-24 "monbulkfamilyclinic.futurehealthtoday.com.au":{"serverapi":"https://MFC-DT-1903:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTProgramUpdated", "analyticsid": "G-NW5C4XV9MG"},  
          // withdraw FHT on 20/10 "morelandgeneralpractice.futurehealthtoday.com.au":{"serverapi":"https://MGP-FHT:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTprogSiteUpdated", "analyticsid": "G-XR7K12K56X"},  
          // withdraw FHT on 07/10 "morelandwestmc.futurehealthtoday.com.au":{"serverapi":"https://MWMC-TS01:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTprogSiteUpdated", "analyticsid": "G-YSEB6LNLDQ"},  
          "mostynstreetclinic.futurehealthtoday.com.au": { "serverapi": "https://TS:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithUWLandWH", "analyticsid": "G-V1BP1MJYPJ" },
          "mountmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://mmc-BPvsql:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-1BGF7RHB66" },
          //withdraw FHT on 07/10 "mountstreetmedicalcentre.futurehealthtoday.com.au":{"serverapi":"https://MS-PC11:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTprogSiteUpdated", "analyticsid": "G-BVJV50QNQW"},
          "mowbraymedical.futurehealthtoday.com.au": { "serverapi": "https://ADMIN2-PC:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-FGWYT521PX" },
          "murchisonmedicalclinic.futurehealthtoday.com.au": { "serverapi": "https://MMCDT03:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "murchisonmedicalclinic" },
          "myclinicbacchusmarsh.futurehealthtoday.com.au": { "serverapi": "https://MCBMPC09:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-FXDGXBZ1MJ" },
          "myclinicbalaclava.futurehealthtoday.com.au": { "serverapi": "https://MCBPC08:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-ZZ1H12VTBJ" },
          //withdraw FHT on 12/13 "myclinicbourkestreet.futurehealthtoday.com.au":{"serverapi":"https://MCBSPC01:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTProgramUpdated", "analyticsid": "G-376JF85HB4"},
          //withdraw "myclinichopperscrossing.futurehealthtoday.com.au":{"serverapi":"https://MCHCPC04:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTprogSiteUpdated", "analyticsid": "G-P103J9DDNF"},
          "myclinicmelbourne.futurehealthtoday.com.au": { "serverapi": "https://MCMBPC12:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-L1P9X4LVE7" },
          "myclinicsouthbank.futurehealthtoday.com.au": { "serverapi": "https://MCSBPC08:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-FY27TM6BE6" },
          //withdraw FHT on 2024-03-13 "myclinicwerribee.futurehealthtoday.com.au":{"serverapi":"https://mcwvpc03.trhc.local:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTProgramUpdated", "analyticsid": "G-MQ082R8CT3"},
          //withdraw on 2023-08-31 "oakleighfamilymedicalcentre.futurehealthtoday.com.au":{"serverapi":"https://MANAGEMENT:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTProgramUpdated", "analyticsid": "G-YJQHN8EDY8"}, 
          "peakmedicalgpc.futurehealthtoday.com.au": { "serverapi": "https://PMSERVER02:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-R12HTXVBR6" },
          // Withdraw FHT on 2023-03-09 "peakmedicalgpc.futurehealthtoday.com.au":{"serverapi":"https://XXX:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTProgramUpdated", "analyticsid": "G-R12HTXVBR6"},
          //withdraw "phillipsonstreet.futurehealthtoday.com.au":{"serverapi":"https://PHISTC709RH:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTprogSiteUpdated", "analyticsid": "G-PV74E1D0WM"},
          "robinsonstreetmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://RSMC-SCANNER-01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-WYCDC5TRQ4" },
          //withdraw FHT on 2024-03-14 "siamedicalcentrefootscray.futurehealthtoday.com.au":{"serverapi":"https://SIA-FT-DT-09:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTProgramUpdated", "analyticsid": "G-MDFZSLKGFQ"},
          //withdraw "surreyhillsmedical.futurehealthtoday.com.au":{"serverapi":"https://SHMCPC19:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTprogSiteUpdated", "analyticsid": "G-T6MB9X7QVV"},
          "siamedicalcentreboxhill.futurehealthtoday.com.au": { "serverapi": "https://Reception-01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-FY950DZR8M" },
          "siamedicalcentreburwood.futurehealthtoday.com.au": { "serverapi": "https://SIA-BW-DT-26N:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-KQPZ8XX82W" },
          //withdraw on 2023-08-28 "siamedicalcentrecroydon.futurehealthtoday.com.au":{"serverapi":"https://SMC-VSVR-RDS:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTProgramUpdated", "analyticsid": "G-FJWE8N3R4B"},
          //withdraw FHT on 2024-09-10 "siamedicalcentreessendon.futurehealthtoday.com.au": { "serverapi": "https://SIA-ESS-DT-RSLT:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-LD28HXPP2N" },
          "summerhillmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://SMCWS09:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithUWLandWH", "analyticsid": "G-LZ6SS4T6DM" },
          "theclinicfootscray.futurehealthtoday.com.au": { "serverapi": "https://MANAGER1-UP:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramdwithSimplyBandWH", "analyticsid": "G-J79PEPL1VL" },
          //withdraw "theneighbourhoodclinic.futurehealthtoday.com.au":{"serverapi":"https://TNC1:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTprogSiteUpdated", "analyticsid": "G-1JRLY13D2B"},  
          "upperbeaconsfieldgp.futurehealthtoday.com.au": { "serverapi": "https://CS-UBGP:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWHandMeno", "analyticsid": "G-YCY51B0JE6" },
          "victoriaharbourmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://download:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithGood4MumandWH", "analyticsid": "G-M8Q2GYKHCR" },
          "ydmg.futurehealthtoday.com.au": { "serverapi": "https://DMG182v:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithGood4MumandCanaryandWHandMeno", "analyticsid": "G-1M685801Q1" },
          //withdraw FHT on 12-13 "myclinicsouthyarra.futurehealthtoday.com.au":{"serverapi":"https://MCSYPC02:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTProgramUpdated", "analyticsid": "G-31FGSCBL8V"},
          //withdraw FHT on 03-12 "carltonmedicalcentre.futurehealthtoday.com.au":{"serverapi":"https://CMC-LINDA:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTProgramUpdated", "analyticsid": "G-ZJV91YZTCW"},
          "mayfieldmedicalconnection.futurehealthtoday.com.au": { "serverapi": "https://MMC-SQL01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWHandMeno", "analyticsid": "G-L906TVJC2M" },
          "plentyroadmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://ROOM3:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithGood4MumandWHandMeno", "analyticsid": "G-Z5RGYY6SYM" },
          "yarramedicalthehive.futurehealthtoday.com.au": { "serverapi": "https://YMC-DT-RSLTS:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramdwithSimplyBandWH", "analyticsid": "G-6TFCK52TMQ" },
          "altonanorthmedicalgroup.futurehealthtoday.com.au":{"serverapi":"https://FHTSERVER:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTMeno"},
          "activemedical.futurehealthtoday.com.au":{"serverapi":"https://AMED-APP01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH"},

           

          //ACTMed sites
          "thehubmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://REMOTETHM:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-N4BBRFGZES" },
          //withdraw on 2023-10-16 "growmedical.futurehealthtoday.com.au":{"serverapi":"https://GROW-TS01:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTACTMED2", "analyticsid": "G-Z2P9ZYT7D3"},
          "craicchs.futurehealthtoday.com.au": { "serverapi": "https://CRA4605010:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-GENYX525W0" },
          "northshoregeneralpractice.futurehealthtoday.com.au": { "serverapi": "https://NSGP-CONSULT-09:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-YLG5VSMXJK" },
          "inalaprimarycare.futurehealthtoday.com.au": { "serverapi": "https://IPC-LAPTOP03:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-WYX5XLEH2G" },
          "capalabamedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://CMC-RDS:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-K6BE26QHND" },
          "miamifamilymedicalpractice.futurehealthtoday.com.au": { "serverapi": "https://MFMC-SVR-DC:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-12B8351BE6" },
          "silkyoaksmedicalpractice.futurehealthtoday.com.au": { "serverapi": "https://FSERVER01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-F6Y6P1N7MN" },
          "mtsheridanmedicalpractice.futurehealthtoday.com.au": { "serverapi": "https://PRACMANAGER:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-Z1R98M3JZ5" },
          "strathpinegpsuperclinic.futurehealthtoday.com.au": { "serverapi": "https://PM02:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-FH5J7NQ12B" },
          "newportfamilydoctors.futurehealthtoday.com.au": { "serverapi": "https://DOCTOR7:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-PJQTD382FN" },
          "rossleamedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://NUC11-MANAGER:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-ZSHEHX7YP4" },
          "familypracticeatburpengary.futurehealthtoday.com.au": { "serverapi": "https://BURP-PM-2022:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-YP3D2MWP48" },
          "oxfordstreetmedicalcentrebulimba.futurehealthtoday.com.au": { "serverapi": "https://RECLEFT-W10:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-HYMHL1SX1Y" },
          //withdraw on 2023/11/01 "healthcaredoctorsindooroopilly.futurehealthtoday.com.au":{"serverapi":"https://PC-HDI-RECEP-02:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTACTMED3", "analyticsid": "G-2G10Q0R5X1"},
          "carbalmedicalservices.futurehealthtoday.com.au": { "serverapi": "https://CAR-PC-019:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-P2ZF4QLNYE" },
          "redcliffeparadefamilymedicalpractice.futurehealthtoday.com.au": { "serverapi": "https://RPFMP-DOCTOR08:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-1YGYN1SRWM" },
          "thedoctorsmulgraveroad.futurehealthtoday.com.au": { "serverapi": "https://PC-PC24SZ3Z:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-T33BHKP4P8" },
          //withdraw on 2023/11/01 "forestlakedoctors.futurehealthtoday.com.au":{"serverapi":"https://PC-FLD-CONS-05:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTACTMEDTranche2", "analyticsid": "G-6VJQ5EG88S"},
          //withdraw on 2023/11/01 "cannonhillfamilydoctors.futurehealthtoday.com.au":{"serverapi":"https://PC-CHFD-RECEP-03:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTACTMEDTranche2", "analyticsid": "G-G4N4TNDHL2"},
          "mulungu.futurehealthtoday.com.au": { "serverapi": "https://MMC-AAD:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-CP7R5GXXKN" },
          "thegooddoctorsvictoriapoint.futurehealthtoday.com.au": { "serverapi": "https://TS2016:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-09V3FF3VMZ" },
          "manlyvillagemedical.futurehealthtoday.com.au": { "serverapi": "https://NURSE-MVM:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-HMWNF40Z6Y" },
          "healthandwellbeingnorthward.futurehealthtoday.com.au": { "serverapi": "https://HWB-WKS-10:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-W4V40V4BKX" },
          "worldwellnessgroup.futurehealthtoday.com.au": { "serverapi": "https://WWG-VMWIN10:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-JKJXJ2FKZF" },
          "baywestmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://NURSE1-BWMC:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-KTTYVRBRV9" },
          "bestlifemedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://BLRDS01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-3Q3QHTP0N2" },
          "gympieroadmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://SERVER:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-LFG8LBJ29D" },
          //withdraw on 2024-07-24 "haanhealthmedicalcentreuppercoomera.futurehealthtoday.com.au": { "serverapi": "https://manager-HP:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTACTMED3", "analyticsid": "G-3MMV16R50Q" },
          "brayparkfamilydoctors.futurehealthtoday.com.au": { "serverapi": "https://BPFDTS01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-Y3DGRBCVKK" },
          "brayparkmedicalpractice.futurehealthtoday.com.au": { "serverapi": "https://DESKTOP-K1N4SVD:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-R2BZWJ6GZ8" },
          "robinafamilymedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://ROOM3:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-JW8BNVY4ZX" },
          "easystreetmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://FHTSVR:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-FSG46RNMVK" },
          "eatoncentral7dayfamilypractice.futurehealthtoday.com.au": { "serverapi": "https://Eatons-Remote:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-VJTFJ70LTP" },
          "mountgravattfamilypractice.futurehealthtoday.com.au": { "serverapi": "https://WORKSTATION02:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-CPQJZHRVV1" },
          "mcdowallvillagemedicalpractice.futurehealthtoday.com.au": { "serverapi": "https://MVMP-Remote:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-2SFJ2M31FC" },
          "runawaybaydoctors.futurehealthtoday.com.au": { "serverapi": "https://RBDS-SVR-DC:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-2SFJ2M31FC" },
          "albanycreekdoctors.futurehealthtoday.com.au": { "serverapi": "https://ACD-SERVER01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-CS0GSHT0Z3" },
          "kallangurfamilymedicalpractice.futurehealthtoday.com.au": { "serverapi": "https://KALLANGUR-SVR01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-02SV3PSM17" },
          "townsvilleaboriginalandislanderhealthservice.futurehealthtoday.com.au": { "serverapi": "https://TAIHS-APPS:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-Z345SDWEXK" },
          //withdraw FHT on 09-25 "hawthorneclinic.futurehealthtoday.com.au": { "serverapi": "https://HC-WS09:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-F5859H7SKS" },
          "yorkeysmedical.futurehealthtoday.com.au": { "serverapi": "https://YMEDPC-08:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-N5X1KLBZS9" },
          "familypracticeatkallangur.futurehealthtoday.com.au": { "serverapi": "https://KAL-PM-NEW:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-NN0DS5FWFM" },
          "stratfordmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://SVR02:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-LCLMRBBX6Q" },
          "eastbrookeambrosefamilymedical.futurehealthtoday.com.au": { "serverapi": "https://Ebrm3:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-3YXJEHH2MX" },
          "townsvilleandsuburbanmedicalpractice.futurehealthtoday.com.au": { "serverapi": "https://TSMP-ADM-02:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTprogramWH", "analyticsid": "G-LLN7HE7KTV" },

          //WH sites
          "theelmsfamilymedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://WS86:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTWH", "analyticsid": "G-ZG611S56GB" },
          "modernmedicalcarolinesprings.futurehealthtoday.com.au": { "serverapi": "https://QHMCSMCPM01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTWH", "analyticsid": "G-Q0M4TQMZ97" },
          "theclinicsunshine.futurehealthtoday.com.au": { "serverapi": "https://CLINIC-SERVER:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTWH", "analyticsid": "G-YGBV95SPE8" },
          "wyperfeldmedicalclinic.futurehealthtoday.com.au": { "serverapi": "https://SVR04:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTWH", "analyticsid": "G-6P24XGR67V" },
          "evanstmedicalclinic.futurehealthtoday.com.au": { "serverapi": "https://ESC-VSERVER-01:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTWH", "analyticsid": "G-K1TKNWJPR4" },
          "coolaroclinic.futurehealthtoday.com.au":{"serverapi":"https://COOVM-FS:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTWH", "analyticsid": "G-CJ7N318Z2N"},
          "westgatemedicalcentre.futurehealthtoday.com.au":{"serverapi":"https://FHT-PC:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTWH", "analyticsid": "G-X89FBL2FX7"},
          "eleanorclinic.futurehealthtoday.com.au": { "serverapi": "https://EC-DT-RSLTS:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTWH", "analyticsid": "G-ZT403ZTW3J" },
          "romseyandlancefieldmedicalcentre.futurehealthtoday.com.au":{"serverapi":"https://SERVER2:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTWH", "analyticsid": "G-HPLHC7TWRL"},
          "parkwoodgreenmedical.futurehealthtoday.com.au":{"serverapi":"https://PGM-TRM-WS01:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTWH", "analyticsid": "G-JXQLM88PH1"},
          "grantstreetmedicalcentre.futurehealthtoday.com.au":{"serverapi":"https://GSMC-WKS10:57503", "Batch":"fhtProduction", "AlgorithmType":"FHTWH", "analyticsid": "G-M8PKG2E9F4"},

          //GooD4Mum sites
          "benallachurchstreetsurgery.futurehealthtoday.com.au": { "serverapi": "https://MEDSTUD:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTGooD4Mum", "analyticsid": "G-N1JM2HVXFP" },
          "redgummedicalgroup.futurehealthtoday.com.au": { "serverapi": "https://CORMECOSBUD03:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTGooD4Mum", "analyticsid": "G-6MC0HGK623" },

          //Both WH and Good4Mum Sites
          "gordongeneralpractice.futurehealthtoday.com.au": { "serverapi": "https://TREATMENT:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithGood4MumandWH", "analyticsid": "G-MFKQCBZXFT" },
          "oakparkmedicalcentre.futurehealthtoday.com.au": { "serverapi": "https://RC-DT-REC2:57503", "Batch": "fhtProduction", "AlgorithmType": "FHTProgramwithGood4MumandWH", "analyticsid": "G-72QNL7WKS2" },
   


          //Test servers
          "unimelbsimsti.futurehealthtoday.com.au": { "serverapi": "https://localhost:57503", "Batch": "fhtsimlabsites", "AlgorithmType": "FHTDirectT1DMSite", "analyticsid": "G-2J6D0DJK1P" },
          "lowbackpainsimulation.futurehealthtoday.com.au": { "serverapi": "https://localhost:57503", "Batch": "fhtsimlabsites", "AlgorithmType": "FHTLowBackPainSite", "analyticsid": "G-XX32FMDR8C" },
          "uwlsimlab.futurehealthtoday.com.au": { "serverapi": "https://TD-192168141173:57503", "Batch": "fhtsimlabsites", "AlgorithmType": "FHTCanary", "analyticsid": "G-HS1LP7VG9Q" },
          "pancreaticcancersimlablocal.futurehealthtoday.com.au": { "serverapi": "https://localhost:57503", "Batch": "fhtsimlabsites", "AlgorithmType": "FHTPancreaticCancerSite", "analyticsid": "G-EWSLBS9C8P" },
          "pancreaticcancersimlab.futurehealthtoday.com.au": { "serverapi": "https://TD-192168141173:57503", "Batch": "fhtsimlabsites", "AlgorithmType": "FHTPancreaticCancerSite", "analyticsid": "G-EWSLBS9C8P" },

          //Torch sites - separated from FHT web code.
          /*
          //change these two to whatever site you want to test with and check into AWSDeploy
          "httpstesting.futurehealthtoday.com.au": { "serverapi": "https://5850L-151378-W:57503", "Batch": "developer", "AlgorithmType": "trialSite", "analyticsid": "G-2NRNF1G01L" },
          "httpstesting2testurl.futurehealthtoday.com.au": { "serverapi": "http://EC2AMAZ-IE9KSDS:57502", "Batch": "developer", "AlgorithmType": "trialSite", "analyticsid": "G-2NRNF1G01L" },
          "localtesturl.futurehealthtoday.com.au": { "serverapi": "http://localhost:57502", "Batch": "developer", "AlgorithmType": "trialSite", "analyticsid": "G-X8XBM01J3B" },
          "ietest.torchrecruit.com.au": { "serverapi": "https://GRHANITE-demo:57503", "Batch": "torchtestsiteUAT", "AlgorithmType": "demoTorchWiserADAndMindEnabledTest", "analyticsid": "G-DJ5EKZ4T6D" },
*/



          //Validitron
          "kane.futurehealthtoday.com.au": { "serverapi": "https://localhost:57503", "Batch": "fhtProduction", "AlgorithmType": "testSite", "AlgorithmType": "ichampsite" },


     },
};

try {
     // Check remote config to verify if it is enabled
     let shouldUseAwsS3Config = true;
     const windowUrl = window.location.hostname;
     for (const siteUrl in SiteSpecificConfig["SiteConfigurationMapping"]) {
          if ((windowUrl.includes("localhost") && windowUrl.includes(siteUrl)) || windowUrl == siteUrl) {
               // Set site config
               shouldUseAwsS3Config = false;
               break;
          }
     }

     if (shouldUseAwsS3Config) {
          console.log("No URL in \"SiteConfigurationMapping\", getting config from AWS S3");
          let xhr = new XMLHttpRequest();
          xhr.open("GET", ConfigUrl, false);
          xhr.send();
          console.log("Remote config obtained");
          // Remove comments from JSON
          const response = xhr.response.replace(/\\"|"(?:\\"|[^"])*"|(\/\/.*|\/\*[\s\S]*?\*\/)/g, (m, g) => g ? "" : m);
          // Parse config
          const remoteConfig = JSON.parse(response);
          // Copy remote config sections
          const remoteConfigSections = ["AlgorithmSiteMapping", "SiteConfigurationMapping"];
          remoteConfigSections.forEach(section => {
               SiteSpecificConfig[section] = remoteConfig[section];
               console.log(`Config section \"${section}\" set from remote config`);
          });
     }
     else {
          console.log("URL found in \"SiteConfigurationMapping\", using local config");
     }
} catch (err) {
     console.error(err);
}

export default SiteSpecificConfig;
