var Configs = {
  "VersionNumber":"1.0.0",
  
  "SiteConfigurationMappingKeys":{"serverapi":"serverapi", "ReportsDashboard": "ReportsDashboard",
                                    "RecommendationAction": "RecommendationAction", "RetrieveAlgoFromAWS":"RetrieveAlgoFromAWS", "Batch":"Batch"
                                  },
                                  /*
  "BatchSiteConfigurationMapping":{
    "production": {"RetrieveAlgoFromAWS": "false"},
    "milestone0TestSites": {"RetrieveAlgoFromAWS": "false"}, //grhanite-zedmed, localhost
    "milestone1TestSites": {"RetrieveAlgoFromAWS": "false"},
  },
  "SiteConfigurationMapping":{
      //local testing only
     // "localhost:": {"serverapi":"https://api.futurehealthtoday.com.au",  "PatientDetails":"false"}, //change the serverapi to suit your developer needs.
      "localhost:": {"serverapi":"http://localhost:57502", "Batch":"milestone0TestSites", "ReportsDashboard": "true", "RecommendationAction": "true"}, //change the serverapi to suit your developer needs.
      //production
      "anmg.futurehealthtoday.com.au":{"serverapi":"http://FHTServer.ANMG.LOCAL:57502", "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},
      "smc.futurehealthtoday.com.au":{"serverapi":"http://SMC-GRHANITE:57502",          "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},
      "coolaroo.futurehealthtoday.com.au":{"serverapi":"http://COO-WKS23:57502",        "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},
      "corio.futurehealthtoday.com.au":{"serverapi":"http://NBH-WS48:57502",            "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},
      "myclinictarneit.futurehealthtoday.com.au":{"serverapi":"http://MCTPC05:57502",   "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},
      "ghwangaratta.futurehealthtoday.com.au":{"serverapi":"http://GHWNGFHT01:57502",   "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},
      "tmc.futurehealthtoday.com.au":{"serverapi":"http://TMC-DC:57502",                "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},
      "deepdenesurgery.futurehealthtoday.com.au":{"serverapi":"http://PM:57502",        "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},
      "siamooneeponds.futurehealthtoday.com.au":{"serverapi":"http://MHC-WS10:57502",   "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},
      "theclinicsunshine.futurehealthtoday.com.au":{"serverapi":"http://Admin1:57502",  "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},
      "dsgmc.futurehealthtoday.com.au":{"serverapi":"http://SWMC-DT-RSLTS:57502",       "Batch":"production", "ReportsDashboard": "false", "RecommendationAction": "false"},      
      //Test servers
      "ie.futurehealthtoday.com.au":{"serverapi":"http://GRHANITE-demo:57502", "Batch":"milestone1TestSites", 
           "ReportsDashboard": "true", "RecommendationAction": "false"},

      "unimelbzedmedtesting.futurehealthtoday.com.au":{"serverapi":"http://GRHANITE-Zedmed:57502", "Batch":"milestone0TestSites", 
           "ReportsDashboard": "true", "RecommendationAction": "true"},

      "unimelbfhtzedmed.futurehealthtoday.com.au":{"serverapi":"http://GRHANITE-fhtzed:57502", "Batch":"milestone0TestSites", 
           "ReportsDashboard": "true", "RecommendationAction": "false"},

      "unimelbmdtesting.futurehealthtoday.com.au":{"serverapi":"http://GRHANITE-Genie:57502", "Batch":"milestone0TestSites", 
           "ReportsDashboard": "true", "RecommendationAction": "false"},

      //Validitron
      "kane.futurehealthtoday.com.au":{"serverapi":"http://localhost:57502", "Batch":"milestone1TestSites", 
       "ReportsDashboard": "true",  "RecommendationAction": "false"},            
      "localtest.futurehealthtoday.com.au":{"serverapi":"http://localhost:57502", "Batch":"milestone0TestSites", 
      "ReportsDashboard": "true", "RecommendationAction": "false"}, 
  },
  */
  "awsUrl": "https://api.futurehealthtoday.com.au",
  "awsFeedbackUrl": "https://testapi.futurehealthtoday.com.au/v1/api/feedback",
  "awsApiUrl":"https://api.futurehealthtoday.com.au",
  //"allowPasswordResets":"false",
  "checkValidTokenLink":"/CheckValidToken",
  "checkValidWebVersionLink":"/CheckValidWebVersion",
  "getUserInformation":"/GetUserInformation",
  "getAllCandidatesInformation":"/CKDCandidateInformationAll",
  "userLogin":"/login",
  "userRegistration":"/register",
  "changePassword":"/ChangePassword",
  "resetPassword": "/ResetPassword",
  "forgotPassword":"/ForgotPassword",
  "updateMyProfile":"/UpdateMyProfile",
  "updateUserTwoFA":"/UpdateUserTwoFA",
  "getUserTwoFA": "/GetUserTwoFA",
  "validate2FAToken": "/Validate2FAToken",
  "practiceStatsTest":"/PracticeAggregateDataLogin",
  "practicePatientManagement":"/PracticePatientManagement",
  "patientDetails": "/api/PatientDetails",
  "triageScreen":"/InitialTriageSQL",
  "ckdRiskAssessment":"/CKDRecentRiskAssessmentAll",
  "dashboardSummaryCKDNumbers":"/DashboardSummaryCKDNumbers",
  "ckdRiskAssessmentPagination":"/CKDRiskAssessmentPagination",
  "submitPatientDeferral":"/TriageSubmitPatientDeferral",
  "submitDeferForApproval":"/TriageSubmitDeferForApproval",
  "submitApprovedDefer":"/TriageSubmitApprovedDefer",
  "submitPatientUndefer":"/TriageSubmitPatientUnDefer",
  "submitTriageForApproval":"/SubmitTriageForApproval",
  "submitCohortTriageForApproval":"/SubmitCohortTriageForApproval",
  "triageApprovalScreen":"/GetApprovalList",
  "triageApprovalDeferScreen":"/GetApprovalDeferList",
  "getApprovalDeferListForCohort":"/GetApprovalDeferListForCohort",
  "getApprovalRecallListForCohort":"/GetApprovalRecallListForCohort",
  "submitApprovedTriage":"/SubmitApprovedTriage",
  "dismissRecallApproval":"/DismissRecallApproval",
  "dismissDeferApproval":"/DismissDeferApproval",
  "triageRecallScreen":"/GetApprovedList",
  "triageAppointmentScreen":"/GetApprovedList",
  "getRecallListForCohort":"/GetRecallListForCohort",
  "getDeferListForCohort":"/GetDeferListForCohort",
  "submitAwaitingConsultation":"/SubmitAwaitingConsultation",
  "submitCohortRecallAwaitingConsultation":"/SubmitCohortRecallAwaitingConsultation",
  "saveCustomQuickFilter":"/SaveCustomQuickFilter",
  "loadCustomQuickFilter":"/LoadCustomQuickFilter",
  "deleteCustomQuickFilter":"/DeleteCustomQuickFilter",
  "CKDRiskAssessmentPagination":"/CKDRiskAssessmentPagination",
  "submitForRecall":"/SubmitTriageForRecall",
  "submitForCohortRecall":"/SubmitCohortTriageForRecall",
  "createCohort":"/CreateCohort",
  "calculateCohortFiltersSummary":"/CalculateCohortFiltersSummary",
  "calculateCreateCohortFiltersSummaryRefactored":"/CalculateCreateCohortFiltersSummaryRefactored",
  "getUsers":"/GetUsers",
  "getRoles":"/GetRoles",
  "deleteUser":"/DeleteUser",
  "editUsers":"/EditUsers",
  "getStaffEMRUsers":"/GetStaffEMRUsername",
  "getDeferredPatients":"/GetDeferredPatients",
  "getPracticeDashBoard": "/PracticeDashBoard",
  "getPracticeDashBoardRecallsPerMonth": "/PracticeDashBoardRecallsPerMonth",
  "getPracticeDashBoardAllRecommendations": "/PracticeDashBoardAllRecommendations",
  "getReviewCohortStagesSummary":"/ReviewCohortStagesSummary",
  "getLineGraphCohortHistory":"/GetLineGraphCohortHistory",
  "getReviewCohortPatientTrialStatusLineGraph":"/GetReviewCohortPatientTrialStatusLineGraph",
  "getListOfUserCreatedCohorts":"/GetListOfUserCreatedCohorts",
  "getNewsArticles": "/GetTopThreeNews",
  "getAllNews": "/GetAllNewsArticles",
  "removePatientsFromCohort":"/RemovePatientsFromCohort",
  "noCohortSelected":"No cohort selected",
  "cohortQueryAggrid":"/CohortQueryAggrid",
  "saveCohortPreviewPermanently":"/SaveCohortPreviewPermanently",
  "saveTorchCohortPreviewPermanently":"/SaveTorchCohortPreviewPermanently", 
  "submitConsentForTorch":"/SubmitConsentForTorch", 
  "withdrawConsentForTorch":"/WithdrawConsentForTorch",
  "getAllQIActivityForms":"/AllQIActivityForms",
  "getAllParticipants":"/GetAllParticipants",
  "updateQIActivityIntoDatabase": "/UpdateQIActivityIntoDatabase",
  "updateParticipantsTable": "/UpdateParticipantsTable",
  "insertNewQIActivity": "/InsertIntoDatabase",
  "getAllParticipantsTable":"/GetAllParticipants",
  "getRecommendationsList":"/GetRecommendationList",
  "getApprovalRecallListForPOC":"/GetApprovalRecallListForPOC",
  "submitApprovedTriagePOC":"/SubmitApprovedTriagePOC",
  "submitApprovedDeferPOC":"/TriageSubmitApprovedDeferPOC",
  "dismissRecallApprovalPOC":"/DismissRecallApprovalPOC",
  "dismissDeferApprovalPOC":"/DismissDeferApprovalPOC",
  "getComments":"/GetCommentsWithUserandDateandTime",
  "saveComment":"/CreateComments",
  "deleteComment":"/RemoveComments",
  "editComment":"/UpdateComments",
  "getCommentsPOC":"/GetCommentsWithUserandDateandTimePOC",
  "saveCommentPOC":"/CreateCommentsPOC",
  "deleteCommentPOC":"/RemoveCommentsPOC",
  "editCommentPOC":"/UpdateCommentsPOC",
  "getQiRoles": "/GetQiRoles",
  "createEnablePaticipant": "/CreateEnablePaticipant",
  "updateEnablePaticipant": "/UpdateEnablePaticipant",
  "getQiActivities": "/GetQiActivities",
  "getParticipants": "/GetParticipants",
  "getActiveEnableParticipants": "/GetActiveEnableParticipants",
  "getActivityParticipants": "/GetActivityParticipants",
  "getQiRecommendations": "/GetQiRecommendations",
  "getStandardActions": "/GetStandardActions",
  "getRemoteStandardAction": "/GetRemoteStandardAction",
  "getCohortForQi": "/GetCohortForQi",
  "getQIActivitiesAndCohortsForQi": "/GetQIActivitiesAndCohortsForQi",
  "getQiActivity":"/GetQiActivity",
  "createNewActivity": "/CreateNewActivity",
  "updateActivity":"/UpdateActivity",
  "updateQIActivityAndCohorts":"/UpdateQIActivityAndCohorts",
  "updateActivityParticipants": "/UpdateActivityParticipants",
  "getActivityParticipants": "/GetActivityParticipants",
  "updateReflection": "/UpdateReflection",
  "getActivityReflections": "/GetActivityReflections",
  "deleteReflection":"/DeleteRefection",
  "saveActivityRecommendations": "/SaveActivityRecommendations",
  "getActivityRecommendations": "/GetActivityRecommendations",
  "getActivityActions":"/GetActivityActions",
  "updateActivityActions": "/UpdateActivityActions",
  "deleteAction": "/DeleteAction",
  "deleteCohort":"/DeleteCohort",
  "downloadApiLog": "/DownloadApiLog",
  "getLodgeRecommendation": "/GetLodgeRecommendation",
  "lodgeRecommendation": "/LodgeRecommendation",
  "updateRecommendationStatus":"/UpdateRecommendationStatus",
  "cohortReviewDescriptionsAndTotals":"/CohortReviewDescriptionsAndTotals",
  "getRecommendationDescriptions":"/GetRecommendationDescriptions",
  "getAllCreateCohortUIDescriptions":"/getAllCreateCohortUIDescriptions",
  "getRecommendationResolution": "/GetRecommendationResolution",
  "getTriggerDescription": "/GetTriggerDescription",
  "createCohortRefactored": "/CreateCohortRefactored",
  "recalculateTotalCohortPatients":"/RecalculateTotalCohortPatients",
  "getTrialDetails":"/GetTrialDetails",  
  "getTrialForCohort":"/GetTrialForCohort",
  "deferCohortRecommendationsSelectedPatients":"/DeferCohortRecommendationsSelectedPatients",
  "getDeferredRecommendationListWithCohortId":"/GetDeferredRecommendationListWithCohortId",
  "submitCohortPatientUnDefer":"/SubmitCohortPatientUnDefer",
  "submitRecommendationUnDefer":"/SubmitRecommendationUnDefer",
  "snackbarVariants":{
    "success":"success",
    "warning":"warning",
    "error" : "error",
    "info" : "info"
  },
  "triageUserActions":{
    "ApproveRecall":"ApproveRecall",
    "ProposeRecall":"ProposeRecall",
    "ApproveDefer":"ApproveDefer",
    "ProposeDefer":"ProposeDefer",
    "UpdateTrialStatus":"UpdateTrialStatus",
    "AddPatientsToCohort":"AddPatientsToCohort",
    "ApproveConsent":"ApproveConsent",
    "RemoveConsent":"RemoveConsent",
    "DeferCohortRecommendationsActions":"DeferCohortRecommendationsActions",
  },    
  "headers":{
      'Authorization':'Bearer '+localStorage.getItem('tokenInfo'),
      'Content-Type':'application/json',
      'Accept':'application/json',
      'cache-control':'no-cache'
      },
  "urlGetObj":{
          method:'GET',
          processData:false,
          data:''
  },
  "urlPostObj":{
      method:'POST',
      processData:false,
      data:''
  },
  "urlHeader":
  {
      headers: {
          'Content-Type':'application/json',
          'Accept':'application/json',
          'cache-control':'no-cache'
          }
  },
  "userTokenInformation":{
    "claims":"claims",
    "role":"role",
    "name":"name",
    "tokenInfo":"tokenInfo",
    "id":"id",
  },
  //The webAPI is formatting this with [] so we can just do a straight up string contains compare to save time on writing the code to store and iterate through a 
  //data structure.

  "userClaims":{
    "TriageScreen":"[1]",
    "RecallScreenViewAll":"[2]",
    "RecallScreenDiscreetView":"[3]",
    "DeferScreen":"[4]",
    "ApprovalScreenViewAll":"[5]",
    "ApprovalScreenViewForGP":"[6]",
    "ApproveRecallButton":"[7]",
    "ProposeRecallButton":"[8]",
    "ApproveDeferButton":"[9]",
    "ProposeDeferButton":"[10]",
    "UserManagement":"[11]",
    "PracticedashboardScreenViewAll":"[12]",
  },
  "navigationPage" : {
    "dashboardGP": "DashboardGP",
    "landingPageV1": "LandingPageV1",
    "loggedInPage": "LoggedInPage",
    "patientManagementGP": "PatientManagementGP",
    "practiceStatsGP": "PracticeStatsGP",
    "contactPage": "ContactDialog",
    "guidelinePage" : "Guideline",
    "loginPage":"LoginPage",
    "accountPage" : "AccountPage",
    "ckdRiskAssessment":"CKDRiskAssessment",
    "ckdRiskAssessmentPagination":"CKDRiskAssessmentPagination",
    "ckdRiskAssessmentNewDatagrid":"CKDRiskAssessmentNewDatagrid",
    "triageScreenGP":"TriageScreenGP",
    "triageApproval":"TriageApproval",
    "triageRecall":"TriageRecall",
    "triageSuperuser":"TriageSuperuser",
    "patientDetails":"PoCPatientScreen",
    "userManagement": "UserManagement",
    "registerPage":"Register",
    "triagePracticedashboard": "Report"
},
"navigationLabel":{
    "user":"User",
    "dashboard":"Dashboard",
    "patientManagement":"Patient Management",
    "practiceStatistics":"Candidate Interview",
    "triageScreen":"Review",	
    "triageApproval":"Approve",	
    "triageRecall":"Recall",
    "administrator":"Administrator",
    "login":"Login",
    "ckdRiskAssessmentPagination":"CKD RiskAssessment Pagination",
    "ckdRiskAssessmentNewDatagrid":"Patient Triage New DG",
    "guidelineLibrary":"Guidelines",
    "qualityImprovement":"Quality Improvement",
    "patientDetails":"Patient Details",
    "register":"Register",
    "triagePracticedashboard":"Report"
},    
  "loggedInStatus" : {
      "gpLoggedIn" : "gpLoggedIn",
      "adminLoggedIn" : "adminLoggedIn",
      "supportLoggedIn" : "supportLoggedIn",
      "coordinatorLoggedIn" : "coordinatorLoggedIn",
      "practiceManagerLoggedIn" : "practiceManagerLoggedIn",
      "patientLoggedIn" : "patientLoggedIn",
      "superuserLoggedIn":"superuserLoggedIn",
      "practiceNurseLoggedIn":"practiceNurseLoggedIn",
      "leadClinicianLoggedIn":"leadClinicianLoggedIn",
      "practiceReceptionistLoggedIn":"practiceReceptionistLoggedIn",
      "notLoggedIn":"notLoggedIn",
      "pointOfCareLoggedIn":"pointOfCareLoggedIn",
  },
  "rolesOnServer":{
      "gp" : "General Practitioner",
      "gpManager" : "Practice Manager",
      "superuser" : "Super User",
      "practiceNurse":"Practice Nurse",
      "leadClinician":"Lead Clinician",
      "practiceReceptionist":"Practice Receptionist",
      "pointOfCare":"PointOfCare",
  },
  "viewPatientFilters":{
      "filter01":"Diagnosed requiring intensifications",
      "filter02":"Diagnosed requiring treatment initiation",
      "filter03":"Requiring review & CKD coding",
      "filter04":"Abnormal results requiring confirmatory tests",
      "filter05":"Requiring Kidney Health Checks",
      "filter06":"All CKD-related patients requiring action"
  },
  "workflowActions":{
      "action01":"Proceed with Recall",
      "action02":"Generate Management Plan",
      "action03":"Exclude from Review"
  },
  "triageRiskFilter":{
      "all":"All",
      "atRisk":"At-Risk",
      "likely":"Likely",
      "needsDiagnosis":"Review",
      "optimisationBp":"Initiate ACE/ARB",
      "optimisationBpCvd":"Initiate ACE/ARB(BP Ok)",
      "intensifyBp": "Intensify ACE/ARB",
      "optimisationStatin":"Initiate Statins",
      "intensifyStatin":"Intensify Statins",
  },
  "ReportFilter" : {	
    'Ckd_Rec_AtRisk' : 'Kidney Health Check Recommended',	
    'Ckd_Rec_Possible' : 'Possible diagnosis of CKD',	
    'Ckd_Rec_CdCkd' : 'Coding of CKD Recommended',	
    //'Ckd_Rec_IntensifyStatin' : 'Optimisation of statin',	
    //'Ckd_Rec_InitiateStatin' : 'Optimisation of statin',	
    //'Ckd_Rec_IntensifyAceArb' : 'Optimisation of blood pressure',	
    //'Ckd_Rec_InitiateAceArb' : 'Optimisation of blood pressure',
    'Ckd_Rec_IntensifyStatin' : 'Intensify statin',	
    'Ckd_Rec_InitiateStatin' : 'Initiate statin',	
    'Ckd_Rec_IntensifyAceArb' : 'Intensify ACE/ARB',	
    'Ckd_Rec_InitiateAceArb' : 'Initiate ACE/ARB',	
    'Ckd_Rec_InitiateAceArbBpOk' : 'Initiate Ace/Arb for BP in target',
    'PrC_Rec_RepeatPSAOrderFT' : 'At risk of Prostate Cancer',
    'PrC_Rec_FTPSAOnly' : 'At risk of Prostate Cancer',
    'PrC_Rec_ReferToUrologist' : 'At risk of Prostate Cancer',
    'PrC_Rec_ReviewPatientsTest' : 'At risk of Prostate Cancer',
    'PltC_Rec_MaleRepeatPltCount' : 'At risk of platelet elevated cancer',
    'PltC_Rec_FemaleRepeatPltCount' : 'At risk of platelet elevated cancer',
    'PltC_Rec_MaleTwoConsecElevatedPltCount' : 'At risk of platelet elevated cancer',
    'PltC_Rec_FemaleTwoConsecElevatedPltCount' : 'At risk of platelet elevated cancer',
    'PltC_Rec_ReviewPatientsTest' : 'At risk of platelet elevated cancer',
  },
  //It'll be good to be able to map composite keys in javascript. No idea how that is done here currently.
  //There's got to be a better way of doing this. :(
  "triageRiskFilterReverseMap":{
      "All":"all",
      "At-Risk":"atRisk",
      "Likely":"likely",
      "Review":"needsDiagnosis",
      "Optimise BP":"optimisationBp",
      "Optimise Lipids":"optimisationStatin",
  },
  "tokenInfo":"tokenInfo",
  "recentlyUsedTriageCategory":"recentlyUsedTriageCategory",
  "recentlyUsedTriageRisk":"recentlyUsedTriageRisk",
  "displayTutorial":"displayTutorial",
  "separator":",",
  "triageCkdCategoryRiskColumnHideMapping":[
      {"key":"At-Risk","value":["RM01"]},
      {"key":"Likely","value":["RM11","RM13"]},
      {"key":"Review","value":["RM11"]},
      {"key":"Optimise","value":["RM03","RM05","RM07","RM09","RM16"]},
  ],
  "ckdPopulationFilters":{
      "checkBoxCkdRiskFilterAll":"checkBoxCkdRiskFilterAll",
      "checkBoxCkdRiskFilterAtRisk":"checkBoxCkdRiskFilterAtRisk",
      "checkBoxCkdRiskFilterLikely":"checkBoxCkdRiskFilterLikely",
      "checkBoxCkdRiskFilterReviewDiagnosis":"checkBoxCkdRiskFilterReviewDiagnosis",
      "checkBoxCkdRiskFilterOptimiseBp":"checkBoxCkdRiskFilterOptimiseBp",
      "checkBoxCkdRiskFilterIntensifyBp":"checkBoxCkdRiskFilterIntensifyBp",
      "checkBoxCkdRiskFilterOptimiseStatin":"checkBoxCkdRiskFilterOptimiseStatin",
      "checkBoxCkdRiskFilterIntensifyStatin":"checkBoxCkdRiskFilterIntensifyStatin",
      "checkBoxCkdRiskFilterOptimiseBpCvd":"checkBoxCkdRiskFilterOptimiseBpCvd",
  },
  "triageCkdCategoryRiskMapping":{
      "all":["RM01","RM03","RM05","RM07","RM09","RM11","RM13","RM16"],
      "atRisk":["RM01"],
      "likely":["RM13"],
      "needsDiagnosis":["RM11"],
      "optimisationBp":["RM09"],
      "optimisationBpCvd":["RM16"],
      "intensificationBp":["RM07"],
      "optimisationStatin":["RM03"],
      "intensificationStatin":["RM05"]
  },
  "roles":{
    "Unassigned":0,
    "GeneralPractitioner":1,
    "LimitedTorchUser":16    
  },
  "workFlowStatus":{
      "0":"Never recalled",
      "1":"Awaiting Review",
      "2":"Awaiting Approval",
      "3":"Awaiting Recall",
      "4":"Appointment Booked",
      "5":"Complete",
  },
  "TorchWorkFlowStatus":{
    "0":"Patients to be reviewed",
    "1":"Invitation Sent",
    "2":"Excluded",
    "3":"Declined",
    "4":"Referred",
    "5":"Deferred",
    "6":"Enrolled",
    "7":"Reviewed but invitation not sent",
  },
  "TorchWorkFlowStatusOrder":[
    "Unassigned",
    "To be reviewed", 
    "Reviewed but invitation not sent",
    "Invitation sent",
    "To be excluded from this trial",
    "Patient declined this trial",
    "Patient referred",
    "Patient enrolled in this trial"
  ],
  "fhtSoftwareType": 1,
  "torchSoftwareType": 2,
  "workFlowCompleteWaitDelay":30,
  "MBSDefinitions":[
      {"key":"721", "value":"GP Management Plan (GPMP) for chronic disease mgt"},
      {"key":"723", "value":"Team Care Arrangements (TCA) for chronic disease mgt"},
      {"key":"732", "value":"GPMP or TCA review"},
      {"key":"729", "value":"GP contribution to another organisation's care plan"},
      {"key":"731", "value":"GP contribution to Aged Care Facility's care plan"},
      {"key":"900", "value":"Medication review"},
      {"key":"903", "value":"Medication review for patient in aged facility"},
      {"key":"701-707", "value":"Health Assessment"},
      // {"key":"703", "value":"Health Assessment (Kidney health Check)"},
      // {"key":"705", "value":"Health Assessment (Kidney health Check)"},
      // {"key":"707", "value":"Health Assessment (Kidney health Check)"},
      {"key":"715", "value":"Health Assessment for Aboriginal and Torres Strait Islander People"},
      {"key":"10987", "value":"Health Assessment for Aboriginal and Torres Strait Islander People"},
      {"key":"10997", "value":"GPMP or TCA review by nurse practitioner or Aboriginal Health Practitioner on behalf of GP"},
  ],
  "mbsDefinitions":{
      "721":"GP Management Plan (GPMP) for chronic disease mgt",
      "92024":"GP Management Plan (GPMP) for chronic disease mgt",
      "92068":"GP Management Plan (GPMP) for chronic disease mgt",
      "229":"GP Management Plan (GPMP) for chronic disease mgt",
      "92055":"GP Management Plan (GPMP) for chronic disease mgt",
      "92099":"GP Management Plan (GPMP) for chronic disease mgt",
      "723":"Team Care Arrangements (TCA) for chronic disease mgt",
      "92025":"Team Care Arrangements (TCA) for chronic disease mgt",
      "92069":"Team Care Arrangements (TCA) for chronic disease mgt",
      "230":"Team Care Arrangements (TCA) for chronic disease mgt",
      "92056":"Team Care Arrangements (TCA) for chronic disease mgt",
      "92100":"Team Care Arrangements (TCA) for chronic disease mgt",
      "732":"GPMP or TCA review",
      "92028":"GPMP or TCA review",
      "92072":"GPMP or TCA review",
      "233":"GPMP or TCA review",
      "92059":"GPMP or TCA review",
      "92103":"GPMP or TCA review",
      "729":"GP contribution to another organisation's care plan",
      "92026":"GP contribution to another organisation's care plan",
      "92070":"GP contribution to another organisation's care plan",
      "231":"GP contribution to another organisation's care plan",
      "92057":"GP contribution to another organisation's care plan",
      "92101":"GP contribution to another organisation's care plan",
      "731":"GP contribution to Aged Care Facility's care plan",
      "92027":"GP contribution to Aged Care Facility's care plan",
      "92071":"GP contribution to Aged Care Facility's care plan",
      "232":"GP contribution to Aged Care Facility's care plan",
      "92058":"GP contribution to Aged Care Facility's care plan",
      "92102":"GP contribution to Aged Care Facility's care plan",
      "900":"Medication review",
      "903":"Medication review for patient in aged facility",
      "701-707":"Health Assessment",
      "701":"Health Assessment (Kidney health Check)",
      "703":"Health Assessment (Kidney health Check)",
      "705":"Health Assessment (Kidney health Check)",
      "707":"Health Assessment (Kidney health Check)",
      "224":"Health Assessment (Kidney health Check)",
      "225":"Health Assessment (Kidney health Check)",
      "226":"Health Assessment (Kidney health Check)",
      "227":"Health Assessment (Kidney health Check)",
      "715":"Health Assessment for Aboriginal and Torres Strait Islander People",      "715":"Health Assessment for Aboriginal and Torres Strait Islander People",
      "92004":"Health Assessment for Aboriginal and Torres Strait Islander People",      "715":"Health Assessment for Aboriginal and Torres Strait Islander People",
      "92016":"Health Assessment for Aboriginal and Torres Strait Islander People",      "715":"Health Assessment for Aboriginal and Torres Strait Islander People",
      "228":"Health Assessment for Aboriginal and Torres Strait Islander People",
      "92011":"Health Assessment for Aboriginal and Torres Strait Islander People",
      "92023":"Health Assessment for Aboriginal and Torres Strait Islander People",
      "10987":"Health Assessment for Aboriginal and Torres Strait Islander People",
      "93202":"Health Assessment for Aboriginal and Torres Strait Islander People",
      "93200":"Health Assessment for Aboriginal and Torres Strait Islander People",
      "10997":"GPMP or TCA review by nurse practitioner or Aboriginal Health Practitioner on behalf of GP",
      "93201":"GPMP or TCA review by nurse practitioner or Aboriginal Health Practitioner on behalf of GP",
      "93203":"GPMP or TCA review by nurse practitioner or Aboriginal Health Practitioner on behalf of GP",
      "23": "General practice consultation",
      "36": "General practice consultation",
      "10990": "Additional Bulk Billing Payment for General Medical Services",
      "10991": "Additional Bulk Billing Payment for General Medical Services"
  },
  "mbsAlternative":{
    "721": "721(92024, 92068)",
    "723": "723(92025, 92069)",
    "732": "732(92028, 92072)",
    "715": "715(92004, 92016)",
    "10987": "10987(93200, 93202)",
    "10997": "10997(93201, 93203)",
    "729": "729(92026, 92070)",
    "731": "731(92027, 92071)",
  },

  // Filters for Datagrid
  "TestIdToRecommendations":{
    "0":"No Recommendations",
    "1":"Kidney Health Check Due",
    "2":"Possible diagnosis of CKD",
    "3":"Recommend coded diagnosis of CKD",
    "4":"Consider Initiation of ACEI or ARB",
    "5":"Consider Intensification of ACEI or ARB dosage",
    "6":"Consider Initiation of Lipid-lowering Therapy",
    "7":"Consider Intensification of Lipid-lowering Therapy",
    "8":"Consider Initiation of ACEI or ARB BP OK",

    "19":"Repeat PSA test and order Free-to-Total PSA recommended.",
    "20":"Free-to-Total PSA only recommended",
    "21":"Referral to Urologist recommended.",
    "22":"Review of test result and Patient past history recommended.",
    
    "24":"Repeat platelet count recommended - male.",
    "25":"Repeat platelet count recommended - female.",
    "26":"Two raised platelet counts - male.",
    "27":"Two raised platelet counts - female",
    "28":"Invalid result for platelet count.",

    "4000":"At risk of gastrointestinal cancer: repeat blood count and order iron studies recommended. Review for gastrointestinal symptoms and consider other anaemia differential diagnosis.",
    "4001":"At risk of gastrointestinal cancer - male: Consider coeliac serology. If negative, consider upper and lower endoscopy according to guidelines. Consider other anaemia differential diagnosis. Consider management of iron deficiency anaemia.",
    "4002":"At risk of gastrointestinal cancer - female. Consider coeliac serology. If negative, consider upper and lower endoscopy according to guidelines. Consider other anaemia differential diagnosis. Consider management of iron deficiency anaemia.",
    "4003":"At risk of endometrial cancer: If visible haematuria or postmenopausal bleeding present, consider transvaginal ultrasound",

    "5002":"Review of AD medication recommended: discuss WiserAD (deprescribing)",
    "5003":"Consider patient for MIND biomarker study associated with younger onset of neurodegenerative disorders",

    "6000":"Consider Heart Health Check",
    "6001":"At risk of CVD: Consider SAMS tool to assess if statin can be commenced at a low/intermittent dose to reduce CVD risk",
    "6002":"Consider initiation of antihypertensive therapy for CVD prevention",
    "6003":"Consider initiation of statin for CVD primary prevention",
    "6004":"Consider initiation of statin for CVD management",
    "6005":"Consider intensification of lipid-lowering therapy",
    "6006":"Consider initiation or intensification of antihypertensive therapy for management of CKD (BP not at target)",
    "6008":"Consider initiation of anticoagulant therapy",
    "6010":"Ischemic stroke",
    "6013":"Consider initiation of anti-platelet agent",
    "6014":"History of atrial fibrillation and CHA2DS2-VA >1: Consider initiation of anticoagulant therapy",
    "6015":"Blood pressure measurements consistent with hypertension -“ review and code diagnosis",
    "6020":"Consider coding diagnosis of heart failure",
    "6021":"Possible diagnosis of heart failure",
    "6024":"Consider initiation of ACE inhibitor or ARB if left ventricular ejection fraction <50% on echocardiogram",
    "6025":"Consider initiation of spironolactone if left ventricular ejection fraction <50% on echocardiogram and potassium is not elevated",
    "6027":"Consider initiation of beta blocker if left ventricular ejection fraction <50% on echocardiogram",
    "6028":"Consider discussion of smoking cessation",

    "9000":"High risk of type 2 Diabetes. Recommend fasting blood glucose level or HbA1c testing. Consider eligibility for the Life! program.",
    "9001":"Type 2 diabetes likely, requires confirmatory testing with HbA1c or fasting blood glucose or oral glucose tolerance test, unless symptoms are present. If symptoms are present, consider recording diagnosis of type 2 diabetes",
    "9002":"Pathology consistent with type 2 diabetes. Review and code",
    "9003":"Consider optimisation of glycaemia; HbA1c >= 7% (53mmol/mol) ",
    "9004":"Optimisation blood pressure recommended",
    "9005":"Consider initiation or intensification of statin for diabetes management",
  },
  month : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

  "rmGuideLineLink": {
      "RM01" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=21","https://kidney.org.au/"],
      "RM02" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=21","https://kidney.org.au/"],
      "RM03" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=43","https://kidney.org.au/"],
      "RM04" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=43","https://kidney.org.au/"],
      "RM05" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=43","https://kidney.org.au/"],
      "RM06" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=43","https://kidney.org.au/"],
      "RM07" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=42","https://kidney.org.au/"],
      "RM08" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=42","https://kidney.org.au/"],
      "RM09" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=42","https://kidney.org.au/"],
      "RM10" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=42","https://kidney.org.au/"],
      "RM11" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=21","https://kidney.org.au/"],
      "RM12" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=21","https://kidney.org.au/"],
      "RM13" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=21","https://kidney.org.au/"],
      "RM14" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=21","https://kidney.org.au/"],
      "RM15" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=21","https://kidney.org.au/"],
      "RM16" : ["https://kidney.org.au/cms_uploads/docs/ckdm-in-gp-handbook.pdf#page=21","https://kidney.org.au/"],
  },
  "rmMessages":{
      "RM01" : "Kidney Health Check recommended  (eGFR, ACR, BP)",
      "RM02" : "Kidney Health Check recommended  (eGFR, ACR, BP)",
      "RM03" : "Consider Intensification of Lipid-lowering Therapy",
      "RM04" : "Consider Intensification of Lipid-lowering Therapy",
      "RM05" : "Consider Initiation of Statin",
      "RM06" : "Consider Initiation of Statin",
      "RM07" : "Elevated blood pressure despite treatment - consider review",
      "RM08" : "Elevated blood pressure despite treatment - consider review",
      "RM09" : "Consider Initiation of ACEI or ARB",
      "RM10" : "Consider Initiation of ACEI or ARB",
      "RM11" : "Pathology test consistent with CKD - review and code diagnosis.",
      "RM12" : "Pathology test consistent with CKD - review and code diagnosis.",
      "RM13" : "Possible diagnosis of CKD: confirmatory testing recommended",
      "RM14" : "Possible diagnosis of CKD: confirmatory eGFR test recommended",
      "RM15" : "Possible diagnosis of CKD: confirmation urine ACR test recommended",
      "RM16" : "Consider initiation of ACEI or ARB  (note: BP in target)",
  },
  "RecommendationCategories":{
      "Ckd_Rec_AtRisk" : "Kidney Health Check recommended  (eGFR, ACR, BP)",
      "Ckd_Rec_Possible" : "Possible diagnosis of CKD: confirmatory testing recommended",
      "Ckd_Rec_CdCkd" : "Pathology test consistent with CKD - review and code diagnosis",
      "Ckd_Rec_InitiateAceArb" : "Consider Initiation of ACEI or ARB",
      "Ckd_Rec_IntensifyAceArb" : "Elevated blood pressure despite treatment - consider review",
      "Ckd_Rec_InitiateStatin" : "Consider Initiation of Statin",
      "Ckd_Rec_IntensifyStatin" : "Consider Intensification of Lipid-lowering Therapy",     
      "Ckd_Rec_InitiateAceArbBpOk" : "Consider initiation of ACEI or ARB  (note: BP in target)",        
      "PrC_Rec_RepeatPSAOrderFT" : "At risk of Prostate Cancer - Repeat PSA test and order Free-to-Total PSA recommended",
      "PrC_Rec_FTPSAOnly" : "At risk of Prostate Cancer – Free-to-Total PSA only recommended",   
      "PrC_Rec_ReferToUrologist" : "At risk of Prostate Cancer – referral to Urologist recommended",
      "PrC_Rec_ReviewPatientsTest" : "At-risk of Prostate Cancer - Review of PSA test result and Patient past history recommended",
      "PltC_Rec_MaleRepeatPltCount":"Raised platelet count. At risk of cancer (male): Repeat platelet count recommended. Review for symptoms, especially for lung, colorectal, gastro-oesophageal",
      "PltC_Rec_FemaleRepeatPltCount":"Raised platelet count. Raised platelet count. At risk of cancer (female): Repeat platelet count recommended. Review for symptoms, especially for lung, colorectal, gastro-oesophageal, endometrial or ovarian",
      "PltC_Rec_MaleTwoConsecElevatedPltCount":"Two raised platelet counts. At risk of cancer (male): Review for symptoms recommended - especially Lung, Colorectal, Gastro-oesophageal. Consider CXR and faecal occult blood test",
      "PltC_Rec_FemaleTwoConsecElevatedPltCount":"Two raised platelet counts. At risk of cancer (female): Review for symptoms recommended - especially Lung, Colorectal, Gastro-oesophageal, Ovarian or Endometrial cancer. Consider CXR and faecal occult blood test and/or CA-125. If visible haematuria, or symptoms of ovarian cancer consider a direct access to ultrasound scan",
      "PltC_Rec_ReviewPatientsTest":"Invalid result for platelet count: Review test result/unit of measure and Patient past history recommended",
      "MentH_Rec_ReviewADMed":"Review of AD medication recommended: discuss WiserAD (deprescribing)",
      "COVCare_Rec_VitSignEmergency": "CovidCare: Vital signs emergency alert: emergency attention recommended",
      "COVCare_Rec_AbnormalVitSigns": "CovidCare: Abnormal vital signs alert: re-check recommended",
      "COVCare_Rec_NegVitSignsEmergency": "CovidCare: Vital signs negative trend alert: re-check recommended",
      "COVCare_Rec_NegVitSignsReCheck": "CovidCare: Vital signs negative trend alert: emergency attention recommended",
      "COVCare_Rec_EmotionalWarning": "CovidCare: Emotional wellbeing warning: medical attention recommended",
      "GastC_Rec_AnaemiaIronDef": "At risk of gastrointestinal cancer: repeat blood count and order iron studies recommended. Review for gastrointestinal symptoms and consider other anaemia differential diagnosis.",
      "GastC_Rec_MaleTwoMarker": "At risk of gastrointestinal cancer (male): Consider coeliac serology. If negative, consider upper and lower endoscopy according to guidelines. Consider other anaemia differential diagnosis. Consider management of iron deficiency anaemia.",
      "GastC_Rec_FemaleTwoMarker": "At risk of gastrointestinal cancer (female). Consider coeliac serology. If negative, consider upper and lower endoscopy according to guidelines. Consider other anaemia differential diagnosis. Consider management of iron deficiency anaemia.",
      "EndoC_Rec_FemaleTwoMarker": "At risk of endometrial cancer: If visible haematuria or postmenopausal bleeding present, consider transvaginal ultrasound",

      "Cvd_Rec_AtRiskNoCheck": "Consider Heart Health Check",
      "Cvd_Rec_HighRiskNoStatinAllergy": "Consider use of SAMS tool to assess whether statin can be re-commenced at a low or intermittent dose to reduce CVD risk",
      "Cvd_Rec_HighRiskNoBpMed": "Consider initiation of antihypertensive therapy for CVD prevention",
      "Cvd_Rec_HighRiskNoStatin": "Consider initiation of statin for CVD primary prevention",
      "Cvd_Rec_CvdNoStatin": "Diagnosed with CVD but not currently on statin. Consider Initiation of statin",
      "Cvd_Rec_CvdOnStatinMissedLipidsTarget": "Consider intensification of lipid-lowing therapy for CVD management  ",
      "Cvd_Rec_CvdMissedBpTarget": "Consider initiation or intensification of antihypertensive therapy for management of CKD (BP not at target)",
      "Cvd_Rec_AfibAndStrokeNoAnticoagulant": "Consider initiation of anticoagulant therapy"
  },   
  


"ProfessionalGuideLines": {
  "Ckd_Rec_AtRisk" : [{title: "At-Risk of CKD", link: "https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=9"}],
  "Ckd_Rec_Possible" : [{title: "Possible diagnosis of CKD", link:"https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=9"}],
  "Ckd_Rec_CdCkd" : [{title: "Coding of diagnosed CKD", link:"https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=9"}],
  "Ckd_Rec_InitiateAceArb" : [{title: "Initiation of ACE or ARB", link:"https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=46"}],
  "Ckd_Rec_IntensifyAceArb" : [{title: "Intensify of ACE or ARB", link:"https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=46"}],
  "Ckd_Rec_InitiateStatin" : [{title: "Initiation of Statin", link:"https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=75"}],
  "Ckd_Rec_IntensifyStatin" : [{title: "Intensify of Statin", link:"https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=75"}],
  "Ckd_Rec_InitiateAceArbBpOk" : [{title: "Initiation of ACE or ARB", link:"https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=46"}],
  
  "PrC_Rec_RepeatPSAOrderFT" : [{title: "Prostate cancer: Resource card", link:"https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf"}, {title: "PSA testing guidelines", link:"https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"}],
  "PrC_Rec_FTPSAOnly" : [{title: "Prostate cancer: Resource card", link:"https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf"}, {title: "PSA testing guidelines", link:"https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"}],
  "PrC_Rec_ReferToUrologist" : [{title: "Prostate cancer: Resource card", link:"https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf"}, {title: "PSA testing guidelines", link:"https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"}],
  "PrC_Rec_ReviewPatientsTest" : [],
  
  "PltC_Rec_MaleRepeatPltCount": [{title: "Table NEJM Causes of Thrombocytosis", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/ProstateCancerThrombocytosis.png"},{title: "Suspected Cancer Guidelines", link: "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations"},{title: "Lung cancer: symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf"},{title: "Oesophagogastric cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf"},{title: "Colorectal cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf"},{title: "Endometrial cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf"},{title: "Ovarian cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"}],  
  "PltC_Rec_FemaleRepeatPltCount": [{title: "Table NEJM Causes of Thrombocytosis", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/ProstateCancerThrombocytosis.png"},{title: "Suspected Cancer Guidelines", link: "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations"},{title: "Lung cancer: symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf"},{title: "Oesophagogastric cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf"},{title: "Colorectal cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf"},{title: "Endometrial cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf"},{title: "Ovarian cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"}],  
  "PltC_Rec_MaleTwoConsecElevatedPltCount":  [{title: "Table NEJM Causes of Thrombocytosis", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/ProstateCancerThrombocytosis.png"},{title: "Suspected Cancer Guidelines", link: "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations"},{title: "Lung cancer: symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf"},{title: "Oesophagogastric cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf"},{title: "Colorectal cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf"},{title: "Endometrial cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf"},{title: "Ovarian cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"}],  
  "PltC_Rec_FemaleTwoConsecElevatedPltCount":  [{title: "Table NEJM Causes of Thrombocytosis", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/ProstateCancerThrombocytosis.png"},{title: "Suspected Cancer Guidelines", link: "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations"},{title: "Lung cancer: symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf"},{title: "Oesophagogastric cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf"},{title: "Colorectal cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf"},{title: "Endometrial cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf"},{title: "Ovarian cancer: Symptoms and referral card", link: "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"}],  
  "PltC_Rec_ReviewPatientsTest":  [], 
   
  "Cvd_Rec_AtRiskNoCheck": [{title: "Heart Foundation. Time to book a Heart Health Check? 2019", link: "https://www.heartfoundation.org.au/heart-health-education/heart-health-checks"}],
  // These five algorithms use Temporary title names; requirements document does not have titles.
  "Cvd_Rec_CvdNoStatin": [{title: "Heart Foundation. Reducing risk in heart disease.", link: "https://www.heartfoundation.org.au/getmedia/a54598f9-e091-4637-b2be-aeed4244bf1e/Reducing-risk-in-heart-disease.pdf "},{title: "Magic App", link: "https://app.magicapp.org/#/guideline/8L0RME/section/nyw7Vj"}],
  "Cvd_Rec_CvdOnStatinMissedLipidsTarget": [{title: "Heart Foundation. Reducing risk in heart disease.", link: "https://www.heartfoundation.org.au/getmedia/a54598f9-e091-4637-b2be-aeed4244bf1e/Reducing-risk-in-heart-disease.pdf"}],
  "Cvd_Rec_HighRiskNoBpMed" : [{title: "Absolute CVD Risk Full Guidelines", link: "https://www.heartfoundation.org.au/getmedia/4342a70f-4487-496e-bbb0-dae33a47fcb2/Absolute-CVD-Risk-Full-Guidelines_2.pdf "},{title: "CVD Check", link: "https://www.cvdcheck.org.au"}],
  "Cvd_Rec_HighRiskNoStatin" : [{title: "Absolute CVD Risk Full Guidelines", link: "https://www.heartfoundation.org.au/getmedia/4342a70f-4487-496e-bbb0-dae33a47fcb2/Absolute-CVD-Risk-Full-Guidelines_2.pdf "},{title: "CVD Check", link: "https://www.cvdcheck.org.au"},{title: "Aus CVD Risk", link: "www.auscvdrisk.com.au"}],
  "Cvd_Rec_AfibAndStrokeNoAnticoagulant" : [{title: "Heart lung circ", link: "https://www.heartlungcirc.org/action/showPdf?pii=S1443-9506%2818%2931778-5"},{title: "Clinical fact sheet stroke AF", link: "https://www.heartfoundation.org.au/getmedia/eb9cefa4-c201-478b-a787-5402beaccc0a/Clinical_Fact_Sheet_-_Stroke_AF.pdf"}],

  "Cvd_Rec_HighRiskNoStatinAllergy" : [{title: "SAMS Tool", link: "https://www.nps.org.au/professionals/managing-lipids/statin-associated-muscle-symptoms-sams#:~:text=Statins%20have%20been%20associated%20with,of%20harm%20from%20a%20treatment.&text=For%20muscle%2Drelated%20adverse%20effects,(7%25%20to%2029%25)."}],
  "Cvd_Rec_CvdMissedBpTarget": [],

  "Dia_Rec_AtRisk": [{title: "RACGP / Diabetes Australia guidelines, “Management of type 2 diabetes: A handbook for general practice”", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=36"}],
  "Dia_Rec_Possible": [{title: "RACGP / Diabetes Australia guidelines, “Management of type 2 diabetes: A handbook for general practice”", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=39"}],
  "Dia_Rec_CDT2": [{title: "RACGP / Diabetes Australia guidelines, “Management of type 2 diabetes: A handbook for general practice”", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=39"}],
  "Dia_Rec_IntensifyDia": [{title: "Australian Diabetes Society Blood Glucose Treatment Algorithm for type 2 diabetes", link: "http://t2d.diabetessociety.com.au/plan/"}],
  "Dia_Rec_BP": [{title: "National Vascular Disease Prevention Alliance “Guidelines for the management of Absolute cardiovascular disease risk”", link: "https://informme.org.au/en/Guidelines/Guidelines-for-the-assessment-and-management-of-absolute-CVD-risk"}, {title: "RACGP / Diabetes Australia guidelines, “Management of type 2 diabetes: A handbook for general practice”", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=100"}],
  "Dia_Rec_CommenceIntensifyStatin": [{title: "National Vascular Disease Prevention Alliance “Guidelines for the management of Absolute cardiovascular disease risk”", link: "https://informme.org.au/en/Guidelines/Guidelines-for-the-assessment-and-management-of-absolute-CVD-risk"}, {title: "RACGP / Diabetes Australia guidelines, “Management of type 2 diabetes: A handbook for general practice”", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=100"}],


},
"PatientGuideLines": {
  "Ckd_Rec_AtRisk" : [{title: "At-Risk of CKD", link: "https://kidney.org.au/"}],
  "Ckd_Rec_Possible" : [{title: "Possible diagnosis of CKD", link: "https://kidney.org.au/"}],
  "Ckd_Rec_CdCkd" : [{title: "Coding of diagnosed CKD", link: "https://kidney.org.au/"}],
  "Ckd_Rec_InitiateAceArb" : [{title: "Initiation of ACE or ARB", link: "https://kidney.org.au/"}],
  "Ckd_Rec_IntensifyAceArb" : [{title: "Intensify of ACE or ARB", link: "https://kidney.org.au/"}],
  "Ckd_Rec_InitiateStatin" : [{title: "Initiation of Statin", link: "https://kidney.org.au/"}],
  "Ckd_Rec_IntensifyStatin" : [{title: "Intensify of Statin", link: "https://kidney.org.au/"}],
  "Ckd_Rec_InitiateAceArbBpOk" : [{title: "Initiation of ACE or ARB", link: "https://kidney.org.au/"}],

  "PrC_Rec_RepeatPSAOrderFT" : [],
  "PrC_Rec_FTPSAOnly" : [],
  "PrC_Rec_ReferToUrologist" :[],
  "PrC_Rec_ReviewPatientsTest" : [],
  
  "PltC_Rec_MaleRepeatPltCount": [],
  "PltC_Rec_FemaleRepeatPltCount": [],
  "PltC_Rec_MaleTwoConsecElevatedPltCount": [],
  "PltC_Rec_FemaleTwoConsecElevatedPltCount": [],
  "PltC_Rec_ReviewPatientsTest":  [], 

  "Cvd_Rec_AtRiskNoCheck": [],
  "Cvd_Rec_CvdNoStatin": [],
  "Cvd_Rec_HighRiskNoStatinAllergy" : [{title: "Stroke Foundation. Atrial fibrillation.", link: "https://strokefoundation.org.au/About-Stroke/Prevent-Stroke/Atrial-fibrillation"}],

  "Dia_Rec_AtRisk": [{title: "NDSS Understanding pre diabetes fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-understanding-pre-diabetes.pdf"}],
  "Dia_Rec_Possible": [{title: "NDSS understanding type 2 diabetes fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-understanding-type2-diabetes.pdf"}],
  "Dia_Rec_CDT2": [{title: "NDSS understanding type 2 diabetes fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-understanding-type2-diabetes.pdf"}],
  "Dia_Rec_IntensifyDia": [{title: "NDSS medication for type 2 diabetes fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-medications-for-type2-diabetes.pdf"}],
  "Dia_Rec_BP": [{title: "NDSS Looking after your heart fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-looking-after-your-heart.pdf"}],
  "Dia_Rec_CommenceIntensifyStatin": [{title: "NDSS Looking after your heart fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-looking-after-your-heart.pdf"}],

},


"ProfessionalGuideLinesById": {
  1 : [{ title: "At-Risk of CKD", link: "https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf"}],
  2 : [{ title: "Possible diagnosis of CKD", link:"https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf"}],
  3 : [{title: "Coding of diagnosed CKD", link:"https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf"}],
  4 : [{title: "Initiation of ACEI or ARB", link:"https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf"}],
  5 : [{title: "Intensify ACEI or ARB", link:"https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf"}],
  6 : [{title: "Initiation of Statin", link:"https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf"}],
  7 : [{title: "Intensify Statin", link:"https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf"}],
  8 : [{title: "Initiation of ACEI or ARB", link:"https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf"}],
  19 : [{title: "Prostate cancer: Resource card", link:"https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf"}, {title: "PSA testing guidelines", link:"https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"}],
  20 : [{title: "Prostate cancer: Resource card", link:"https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf"}, {title: "PSA testing guidelines", link:"https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"}],
  21 : [{title: "Prostate cancer: Resource card", link:"https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf"}, {title: "PSA testing guidelines", link:"https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"}],
  22 : [],
  24 : [{title: "Thrombocytosis and risk of cancer", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/ThrombocytosisRecommendedfollow-upforpeopleatriskofanundiagnosedcancerv4.2021.7.2021.pdf"}],  
  25 : [{title: "Thrombocytosis and risk of cancer", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/ThrombocytosisRecommendedfollow-upforpeopleatriskofanundiagnosedcancerv4.2021.7.2021.pdf"}],  
  26 : [{title: "Thrombocytosis and risk of cancer", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/ThrombocytosisRecommendedfollow-upforpeopleatriskofanundiagnosedcancerv4.2021.7.2021.pdf"}],  
  27: [{ title: "Thrombocytosis and risk of cancer", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/ThrombocytosisRecommendedfollow-upforpeopleatriskofanundiagnosedcancerv4.2021.7.2021.pdf" }],
  28: [],
  40: [
    { title: "KDIGO 2022 Diabetes Management in Chronic Kidney Disease Synopsis", link: "https://kdigo.org/wp-content/uploads/2023/03/KDIGO-2022-Diabetes-Guideline-Annals-Synopsis-Final.pdf" },
    { title: "Baigent et al. The Lancet (2022)", link: "https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(22)02074-8/fulltext" },
    { title: "Initiation of SGLT2 inhibitor", link: "https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf" },
  ],
  41: [
    { title: "KDIGO 2022 Diabetes Management in Chronic Kidney Disease Synopsis", link: "https://kdigo.org/wp-content/uploads/2023/03/KDIGO-2022-Diabetes-Guideline-Annals-Synopsis-Final.pdf" },
    { title: "Oka et al. Hypertension (2022)", link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8823908/" },
    { title: "Initiation of mineral corticoid receptor antagonist", link: "https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf" },
  ],
  45: [{ title: "Kidney Health Check", link: "https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf" }],
  4000 : [{title: "Anaemia and risk of cancer. Summary of Evidence", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/AnaemiaRecommendedfollow-upforpeopleatriskofanundiagnosedcancerv3.16.7.2021.pdf"},{title: "Table: Causes of anaemia in adults. UpToDate 2017", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/Causesofanaemiainadults.JPG"}],
  4001 : [{title: "Anaemia and risk of cancer (MALES). Summary of Evidence", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/MALEAnaemiaRecommendedfollow-upforpeopleatriskofanundiagnosedcancerv0.4.16.7.20.pdf"},{title: "Table: Causes of anaemia in adults. UpToDate 2017", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/Causesofanaemiainadults.JPG"}],
  4002 : [{title: "Anaemia and risk of cancer (FEMALES). Summary of Evidence", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FEMALEAnaemiaRecommendedfollow-upforpeopleatriskofanundiagnosedcancerv0.4.16.7.2021.pdf"},{title: "Table: Causes of anaemia in adults. UpToDate 2017", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/Causesofanaemiainadults.JPG"}],
  4003 : [],
  5002 : [{title: "WiserAD trial websites", link: "https://medicine.unimelb.edu.au/research-groups/general-practice-research/mental-health-program/wiserad-a-randomised-trial-of-a-structured-online-intervention-to-promote-and-support-antidepressant-de-prescribing-in-primary-care"}],
  
  6000 : [{title: "Heart Foundation. Time to book a Heart Health Check? 2019", link: "https://www.heartfoundation.org.au/heart-health-education/heart-health-checks"}],
  6001 : [{title: "SAMS Tool", link: "https://www.nps.org.au/professionals/managing-lipids/statin-associated-muscle-symptoms-sams#:~:text=Statins%20have%20been%20associated%20with,of%20harm%20from%20a%20treatment.&text=For%20muscle%2Drelated%20adverse%20effects,(7%25%20to%2029%25)."}],
  

  6004 : [{title: "Heart Foundation: Reducing risk in heart disease", link: "https://www.heartfoundation.org.au/getmedia/a54598f9-e091-4637-b2be-aeed4244bf1e/Reducing-risk-in-heart-disease.pdf"},{title: "Clinical Guidelines for Stroke Management - Cholesterol lowering therapy", link: "https://app.magicapp.org/#/guideline/8L0RME/section/nyw7Vj"}],
  6005 : [{title: "Heart Foundation: Reducing risk in heart disease", link: "https://www.heartfoundation.org.au/getmedia/a54598f9-e091-4637-b2be-aeed4244bf1e/Reducing-risk-in-heart-disease.pdf"}],
  6002 : [{title: "Absolute cardiovascular disease risk guidelines", link: "https://www.heartfoundation.org.au/getmedia/4342a70f-4487-496e-bbb0-dae33a47fcb2/Absolute-CVD-Risk-Full-Guidelines_2.pdf"},{title: "Australian absolute cardiovascular disease risk calculator", link: "https://www.cvdcheck.org.au"},{title: "Online CVD Guidelines", link: "https://www.auscvdrisk.com.au"}],
  6003 : [{title: "Absolute cardiovascular disease risk guidelines", link: "https://www.heartfoundation.org.au/getmedia/4342a70f-4487-496e-bbb0-dae33a47fcb2/Absolute-CVD-Risk-Full-Guidelines_2.pdf"},{title: "Australian absolute cardiovascular disease risk calculator", link: "https://www.cvdcheck.org.au"},{title: "Online CVD Guidelines", link: "https://www.auscvdrisk.com.au"}],  
  6006: [{title: "Heart Foundation: Reducing risk in heart disease", link: "https://www.heartfoundation.org.au/getmedia/a54598f9-e091-4637-b2be-aeed4244bf1e/Reducing-risk-in-heart-disease.pdf"}],
  6008: [{title: "Stroke Foundation: Living guidelines for stroke-anticoagulant therapy", link: "https://app.magicapp.org/#/guideline/8L0RME/section/EgV9pn "}],
  6013 : [{title: "Heart Foundation: Reducing risk in heart disease", link: "https://www.heartfoundation.org.au/getmedia/a54598f9-e091-4637-b2be-aeed4244bf1e/Reducing-risk-in-heart-disease.pdf"},{title: "Heart Foundation: Management guidelines for acute coronary syndrome", link: "https://www.mja.com.au/journal/2016/205/3/national-heart-foundation-australia-and-cardiac-society-australia-and-new"},{title: "Stroke Foundation: Living guidelines for stroke- antiplatelet therapy", link: "https://app.magicapp.org/#/guideline/8L0RME/section/j1qQXj"}],  
  6014 : [{title: "Heart Foundation: clinical guidelines for AF", link: "https://www.heartlungcirc.org/action/showPdf?pii=S1443-9506%2818%2931778-5"},
          {title: "Heart Foundation: stroke prevention in non-valvular AF", link: "https://www.heartfoundation.org.au/getmedia/eb9cefa4-c201-478b-a787-5402beaccc0a/Clinical_Fact_Sheet_-_Stroke_AF.pdf"}],  
  6015 : [{title: "National Heart Foundation of Australia. Guideline for the diagnosis and management of hypertension in adults - 2016. Melbourne: National Heart Foundation of Australia; 2016.", link: "https://www.heartfoundation.org.au/getmedia/c83511ab-835a-4fcf-96f5-88d770582ddc/PRO-167_Hypertension-guideline-2016_WEB.pdf"}],  
  6018: [
    {title: "RACGP Redbook Appendix 2B: Dutch Lipid Clinic Network Criteria", link: "https://www.racgp.org.au/FSDEDEV/media/documents/Clinical%20Resources/Guidelines/Red%20Book/Appendix-2B.pdf"},
    {title: "AJGP: FH: A guide for general practice", link: "https://www1.racgp.org.au/getattachment/53a159fd-8cb9-40c1-8a2e-69d14fb98011/Familial-hypercholesterolaemia.aspx"},
    {title: "HLC: Integrated guidance - FH in Australia", link: "https://www.sciencedirect.com/science/article/pii/S1443950620315055?utm_campaign=%20hhn_newsletter_jan21&utm_medium=Email&utm_source=ExactTarget#fn3"},
    {title: "FH Australasia Network resources", link: "https://www.athero.org.au/fh/"},
  ],
  6020: [{title: "SHAPE Study", link: "https://bmcpublichealth.biomedcentral.com/articles/10.1186/s12889-020-08781-8"}],
  6021: [{title: "SHAPE Study", link: "https://bmcpublichealth.biomedcentral.com/articles/10.1186/s12889-020-08781-8"}],
  6024: [{title: "Guidelines for the Prevention, Detection, and Management of Heart Failure in Australia 2018", link: "https://www.heartlungcirc.org/article/S1443-9506(18)31777-3/fulltext"}, {title: "Heart Failure tools and resources", link: "https://www.heartfoundation.org.au/for-professionals/heart-failure-tools-and-resources"}],
  6025: [{title: "Guidelines for the Prevention, Detection, and Management of Heart Failure in Australia 2018", link: "https://www.heartlungcirc.org/article/S1443-9506(18)31777-3/fulltext"}, {title: "Heart Failure tools and resources", link: "https://www.heartfoundation.org.au/for-professionals/heart-failure-tools-and-resources"}],
  6027: [{title: "Guidelines for the Prevention, Detection, and Management of Heart Failure in Australia 2018", link: "https://www.heartlungcirc.org/article/S1443-9506(18)31777-3/fulltext"}, {title: "Heart Failure tools and resources", link: "https://www.heartfoundation.org.au/for-professionals/heart-failure-tools-and-resources"}],
  6028: [{title: "Reducing risk in heart disease", link: "https://www.heartfoundation.org.au/getmedia/a54598f9-e091-4637-b2be-aeed4244bf1e/Reducing-risk-in-heart-disease.pdf"}],

  9000: [{title: "RACGP / Diabetes Australia guidelines, “Management of type 2 diabetes: A handbook for general practice”", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=36"}, {title: "Diabetes Life! referral form", link: "https://www.lifeprogram.org.au/dmdocuments/documents/128-gp-referral-form-04-2020/file"}],
  9001: [{title: "RACGP / Diabetes Australia guidelines, “Management of type 2 diabetes: A handbook for general practice”", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=39"}],
  9002: [{title: "RACGP / Diabetes Australia guidelines, “Management of type 2 diabetes: A handbook for general practice”", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=39"}],
  9003: [{title: "Australian Diabetes Society Blood Glucose Treatment Algorithm for type 2 diabetes", link: "http://t2d.diabetessociety.com.au/plan/"},{title: "Australian Evidence-Based Clinical Guidelines for Diabetes", link: "https://app.magicapp.org/#/guideline/E5AbPE"}],
  9004: [{title: "National Vascular Disease Prevention Alliance “Guidelines for the management of Absolute cardiovascular disease risk”", link: "https://informme.org.au/en/Guidelines/Guidelines-for-the-assessment-and-management-of-absolute-CVD-risk"}, {title: "RACGP / Diabetes Australia guidelines, “Management of type 2 diabetes: A handbook for general practice”", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=100"}],
  9005: [{title: "National Vascular Disease Prevention Alliance “Guidelines for the management of Absolute cardiovascular disease risk”", link: "https://informme.org.au/en/Guidelines/Guidelines-for-the-assessment-and-management-of-absolute-CVD-risk"}, {title: "RACGP / Diabetes Australia guidelines, “Management of type 2 diabetes: A handbook for general practice”", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=100"}],
  9018: [{title: "Australian Evidence-based clinical guidelines for diabetes “Optimal add-on medication”", link: "https://app.magicapp.org/#/guideline/E5AbPE/section/EZ5Jln"},
         {title: "Australian Diabetes Society, “Australian type 2 diabetes glycaemic management algorithm”", link: "https://treatment.diabetessociety.com.au/plan/"},
         {title: "KDIGO", link: "https://kdigo.org/wp-content/uploads/2020/10/KDIGO-2020-Diabetes-in-CKD-GL.pdf"},
         {title: "NPS SGLT2i Beyond Diabetes", link: "https://www.nps.org.au/australian-prescriber/articles/sodium-glucose-co-transporter-2-inhibitors-beyond-diabetes"},
        ],  

  16000: [{title: "Australian STI management guidelines > Pregnant people", link: "https://sti.guidelines.org.au/populations-and-situations/pregnant-people/"},
          {title: "Pregnancy care guidelines –Syphilis", link: "https://www.health.gov.au/resources/pregnancy-care-guidelines/part-f-routine-maternal-health-tests/syphilis#:~:text=Kirby%20Institute%202018b.-,Risk%20factors,-Syphilis%20in%20Australia"} 
         ],
  16001: [{title: "Australian STI management guidelines > Standard Asymptomatic Check-up", link: "https://sti.guidelines.org.au/standard-asymptomatic-checkup/"}],

  20000: [{title: "National Asthma Council Australia, Australian Asthma Handbook version 2.2", link: "https://www.asthmahandbook.org.au/management/adults"},
          {title: "National Asthma Council Australia, Selecting and adjusting asthma medications chart", link: "https://www.nationalasthma.org.au/living-with-asthma/resources/health-professionals/charts/selecting-and-adjusting-asthma-medications"}     
    ],
  20001: [{title: "Lung Foundation Australia, COPD-X Concise Guide", link: "https://lungfoundation.com.au/resources/copd-x-concise-guide/"},
          {title: "Lung Foundation Australia, Stepwise Management of Stable COPD factsheet ", link: "https://lungfoundation.com.au/resources/stepwise-management-of-stable-copd/"}     
    ],
  20002: [{title: "National Asthma Council Australia, Australian Asthma Handbook version 2.2", link: "https://www.asthmahandbook.org.au/clinical-issues/copd"},
          {title: "National Asthma Council Australia, Selecting and adjusting asthma medications chart ", link: "https://www.nationalasthma.org.au/living-with-asthma/resources/health-professionals/charts/selecting-and-adjusting-asthma-medications"},     
          {title: "Lung Foundation Australia, Stepwise Management of Stable COPD factsheet", link: "https://lungfoundation.com.au/resources/stepwise-management-of-stable-copd/"},
          {title: "Lung Foundation Australia, COPD Guidelines: The COPD-X Plan version 2.71 (Section C4.1 on p31)", link: "https://copdx.org.au/wp-content/uploads/2023/09/WEBSITE_COPDX-V2-70_FINAL.pdf"},
        ],
  20003: [{ title: "RACGP guidelines - Management of Type 2 Diabetes: A handbook for general practice 2020 (p.23)", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx" }],
  114000:[{title: "Unintended weight loss resource", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/Unintended+Weight+Loss+Resources+for+GPs_20230608.pdf" }],
  114001:[{title: "Unintended weight loss resource", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/Unintended+Weight+Loss+Resources+for+GPs_20230608.pdf" }],  
  114002:[{title: "Unintended weight loss resource", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/Unintended+Weight+Loss+Resources+for+GPs_20230608.pdf" }],  
  114003:[{title: "Unintended weight loss resource", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/Unintended+Weight+Loss+Resources+for+GPs_20230608.pdf" }],  
  114004:[{title: "Unintended weight loss resource", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/Unintended+Weight+Loss+Resources+for+GPs_20230608.pdf" }],  
  114005:[{title: "Unintended weight loss resource", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/Unintended+Weight+Loss+Resources+for+GPs_20230608.pdf" }],  
  114006:[{title: "Unintended weight loss resource", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/Unintended+Weight+Loss+Resources+for+GPs_20230608.pdf" }],  
  22001:[{title: "GESA National Hepatitis B Management Consensus Statement", link: "https://www.gesa.org.au/resources/clinical-practice-resources/hepatitis-b-virus-hbv-consensus-statement/" }],  
  24002: [{ title: "GESA consensus statement: Recommendations for the assessment of metabolic dysfunction-associated fatty liver disease (MAFLD) in primary care (2024)", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Clinical%20Practice%20Resources/MAFLD/MAFLD%20consensus%20statement%202024.pdf" },
    { title: "GESA Summary of recommendations for the assessment of metabolic dysfunction-associated fatty liver disease (MAFLD) in primary care (2023)", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Clinical%20Practice%20Resources/MAFLD/GESA%20MAFLD%20Recommendations%20Summary.pdf" },
    { title: "GESA GP Information on Understanding Liver Tests", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Clinical%20Practice%20Resources/Understanding%20Liver%20Tests/Understanding%20Liver%20Tests_2024_update%20(1).pdf" },
    { title: "Bulk Billed Liver Elastography locations (Western Australia)", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/WA_Elastography_clinic+list+v3.0_09.09.2024_CLEAN.pdf" },
    { title: "Bulk Billed Liver Elastography locations (Victoria)", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/VIC_Elastography_clinic+list+v2.0_06.06.2024_CLEAN.pdf" }],
  24003: [{ title: "GESA consensus statement: Recommendations for the assessment of metabolic dysfunction-associated fatty liver disease (MAFLD) in primary care (2024)", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Clinical%20Practice%20Resources/MAFLD/MAFLD%20consensus%20statement%202024.pdf" },
          { title: "GESA Summary of recommendations for the assessment of metabolic dysfunction-associated fatty liver disease (MAFLD) in primary care (2023)", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Clinical%20Practice%20Resources/MAFLD/GESA%20MAFLD%20Recommendations%20Summary.pdf" },
          { title: "GESA GP Information on Understanding Liver Tests", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Clinical%20Practice%20Resources/Understanding%20Liver%20Tests/Understanding%20Liver%20Tests_2024_update%20(1).pdf" }],
  25000:[{title: "Managing low back pain in primary care", link: "https://www.nps.org.au/australian-prescriber/articles/managing-low-back-pain-in-primary-care"},
         {title: "Imaging in adults with acute low back pain", link: "https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/first-do-no-harm/gp-resources/imaging-in-adults-with-acute-low-back-pain"},
         {title: "Understanding low back pain booklet", link: "https://www.mq.edu.au/research/research-centres-groups-and-facilities/groups/spinal-pain-research-group/our-resources/understanding-low-back-pain-booklet"}
        ],
  
  26000: [
          {title: "Life! program referral pathway", link: "https://lifeprogram.org.au/wp-content/uploads/2022/03/Life-Referral-pathway-GP-Case-Finding-Agreement-FEB-2022-WEB.pdf"},
          {title: "Life! program referral form", link: "https://lifeprogram.org.au/wp-content/uploads/2022/03/Life-GP-Referral-Form-FEB-2022-WEB-002.pdf"},
          {title: "ADIPS Consensus Guidelines for GDM", link: "http://www.adips.org/downloads/ADIPSConsensusGuidelinesGDM-03.05.13VersionACCEPTEDFINAL.pdf"},
          {title: "GP gestational diabetes follow up flowchart", link: "https://www.ndss.com.au/wp-content/uploads/gestational-diabetes-hp-flowchart-follow-up.pdf"},
        ],
  26001:[{title: "ADIPS Consensus Guidelines for GDM", link: "http://www.adips.org/downloads/ADIPSConsensusGuidelinesGDM-03.05.13VersionACCEPTEDFINAL.pdf" }],
  26002: [{title: "ADIPS Consensus Guidelines for GDM", link: "http://www.adips.org/downloads/ADIPSConsensusGuidelinesGDM-03.05.13VersionACCEPTEDFINAL.pdf" },
          {title: "RACGP  Management of type 2 diabetes: A handbook for general practice", link: "https://www.racgp.org.au/getattachment/41fee8dc-7f97-4f87-9d90-b7af337af778/Management-of-type-2-diabetes-A-handbook-for-general-practice.aspx#page=183" }], 
  31000: [{title: "A guide for pancreatic cancer", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/Guidelines_Investigating+Patients+Who+May+Have+Undiagnosed+Pancreatic+Cancer_Dec2023.pdf"},
          {title: "Flowchart for follow-up", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/New+Onset+Diabetes_Flowchart.png"}     
        ],

  31001: [{title: "A guide for pancreatic cancer", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/Guidelines_Investigating+Patients+Who+May+Have+Undiagnosed+Pancreatic+Cancer_Dec2023.pdf"},
          {title: "Flowchart for follow-up", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/New+Onset+Diabetes_Flowchart.png"}     
        ],
  31004 : [{ title: "A guide for pancreatic cancer", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/Guidelines_Investigating+Patients+Who+May+Have+Undiagnosed+Pancreatic+Cancer_Dec2023.pdf"}],

  33000:[{title: "2023 Practitioner Toolkit for Managing Menopause", link: "https://www.menopause.org.au/images/pics/ptmm/a-practitioners-toolkit-for-managing-menopause.pdf" }],  
  33001:[{title: "2023 Practitioner Toolkit for Managing Menopause", link: "https://www.menopause.org.au/images/pics/ptmm/a-practitioners-toolkit-for-managing-menopause.pdf#page=8" }],
  33002:[{title: "2023 Practitioner Toolkit for Managing Menopause", link: "https://www.menopause.org.au/images/pics/ptmm/a-practitioners-toolkit-for-managing-menopause.pdf#page=8" }],
},
"PatientGuideLinesById": {
  1 : [{title: "At-Risk of CKD", link: "https://kidney.org.au/resources"}],
  2 : [{title: "Possible diagnosis of CKD", link: "https://kidney.org.au/resources/detecting-managing-ckd-resource-library"}],
  3 : [{title: "Coding of diagnosed CKD", link: "https://kidney.org.au/resources/newly-diagnosed-with-ckd-resource-library"}],
  4 : [{title: "Initiation of ACEI or ARB", link: "https://kidney.org.au/resources/detecting-managing-ckd-resource-library"}],
  5 : [{title: "Intensify ACEI or ARB", link: "https://kidney.org.au/resources/detecting-managing-ckd-resource-library"}],
  6 : [{title: "Initiation of Statin", link: "https://kidney.org.au/resources/detecting-managing-ckd-resource-library"}],
  7 : [{title: "Intensify Statin", link: "https://kidney.org.au/resources/detecting-managing-ckd-resource-library"}],
  8 : [{title: "Initiation of ACEI or ARB", link: "https://kidney.org.au/resources/detecting-managing-ckd-resource-library"}],
  19 : [{title: "Patient information sheet – PSA", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-PSA.pdf"}],
  20 : [{title: "Patient information sheet – PSA", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-PSA.pdf"}],
  21 : [{title: "Patient information sheet – PSA", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-PSA.pdf"}],
  22 : [],
  24 : [{title: "Patient information sheet – raised platelet count", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-raisedplateletcount.pdf"}],
  25 : [{title: "Patient information sheet – raised platelet count", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-raisedplateletcount.pdf"}],
  26 : [{title: "Patient information sheet – raised platelet count", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-raisedplateletcount.pdf"}],   
  27 : [{title: "Patient information sheet – raised platelet count", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-raisedplateletcount.pdf"}],
  28: [],
  45: [{ title: "Chronic kidney disease", link: "https://kidney.org.au/resources" }],
  4000 : [{title: "Patient information sheet - iron deficiency anaemia", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-irondeficiencyanaemia.pdf"}],
  4001 : [{title: "Patient information sheet - iron deficiency anaemia", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-irondeficiencyanaemia.pdf"}],
  4002 : [{title: "Patient information sheet - iron deficiency anaemia", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-irondeficiencyanaemia.pdf"}],
  4003 : [],
  5002 : [],

  6000: [],
  6004 : [],
  6005 : [], 
  6002 : [],  
  6003 : [],  
  6008 : [],

  6001 : [],
  6006 : [],
  6014 : [{title: "Stroke Foundation: AF", link: "https://strokefoundation.org.au/About-Stroke/Prevent-Stroke/Atrial-fibrillation"}],
  6018 : [
    {title: "Heart Foundation consumer FH resource", link: "https://www.heartfoundation.org.au/getmedia/7edd455f-1b3b-4e30-b20c-487720be68e7/familial-hypercholesterolaemia.pdf"},
    {title: "FH Australasia Network resources", link: "https://www.athero.org.au/fh/"}
  ],
  6024 : [{title: "Heart Foundation - What is Heart Failure? resources for patients", link: "https://www.heartfoundation.org.au/your-heart/heart-failure"}],
  6025 : [{title: "Heart Foundation - What is Heart Failure? resources for patients", link: "https://www.heartfoundation.org.au/your-heart/heart-failure"}],
  6027 : [{title: "Heart Foundation - What is Heart Failure? resources for patients", link: "https://www.heartfoundation.org.au/your-heart/heart-failure"}],
  6028 : [
    {title: "Quit Smoking - quit.org.au", link: "https://www.quit.org.au/"},
    {title: "Supporting smoking cessation: A guide for health professionals", link: "https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/supporting-smoking-cessation"},
    {title: "Quit Smoking - Stroke Foundation", link: "https://strokefoundation.org.au/About-Stroke/Prevent-Stroke/Smoking"}
  ],

  9000: [{title: "NDSS Understanding pre diabetes fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-understanding-pre-diabetes.pdf"}],
  9001: [{title: "NDSS understanding type 2 diabetes fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-understanding-type2-diabetes.pdf"}],
  9002: [{title: "NDSS understanding type 2 diabetes fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-understanding-type2-diabetes.pdf"}],
  9003: [{title: "NDSS medication for type 2 diabetes fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-medications-for-type2-diabetes.pdf"}],
  9004: [{title: "NDSS Looking after your heart fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-looking-after-your-heart.pdf"}],
  9005: [{title: "NDSS Looking after your heart fact sheet", link: "https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-looking-after-your-heart.pdf"}],
  9018: [{title: "Mayo Clinic Diabetes Medication Choice Decision Conversation Aid", link: "	https://diabetesdecisionaid.mayoclinic.org/index"}],

  16000: [{title: "Women and syphilis factsheet", link: "https://www.health.gov.au/sites/default/files/documents/2021/07/women-and-syphilis.pdf"}],
  16001: [{title: "Department of Health and Aged Care.Don’t fool around with syphilis.", link: "https://www.health.gov.au/resources/collections/dont-fool-around-with-syphilis-resources "}],

  20000: [{title: "National Asthma Council Australia patient & carer resource brochure: My Asthma Guide 2022", link: "https://d8z57tiamduo7.cloudfront.net/resources/NAC011-My-Asthma-Guide-Update-2022_Web.pdf"}],
  20001: [{title: "Lung Foundation Australia patient & carer resource: COPD Factsheet Jun 2018", link: "https://lungfoundation.com.au/resources/copd-fact-sheet/"}],

  20002: [
    {title: "National Asthma Council Australia patient & carer resource brochure: My Asthma Guide 2022", link: "https://d8z57tiamduo7.cloudfront.net/resources/NAC011-My-Asthma-Guide-Update-2022_Web.pdf"},
    {title: "Lung Foundation Australia patient & carer resource: COPD factsheet Jun 2018 ", link: "https://lungfoundation.com.au/resources/copd-fact-sheet/"},

    ],
  20003: [{title: "Australian health advice from HealthDirect: HbA1c test", link: "https://www.healthdirect.gov.au/hba1c-test#:~:text=If%20you%20have%20not%20previously,HbA1c%20of%206.5%2D7%25"}],
  24002: [{ title: "IDEAL care trial Liver Health patient information sheet", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/IDEAL+Care+pt+resource+v1.5_FINAL_LF_approved.pdf" },
    { title: "Lifestyle recommendations for liver health. ", link: "https://liver.org.au/living-well/" },
    { title: "What is a fibroscan?", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Fibroscan/FibroScan_2022_APPROVED.pdf" },
    { title: "What is fatty liver disease?", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Fatty%20Liver/Fatty%20Liver%20Disease%202021%20final.pdf" },
    { title: "Treatment for hepatitis B", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Treatment%20for%20Hepatitis%20B/Treatment%20for%20Hepatitis%20B_2022_APPROVED.pdf" },
    { title: "Hepatitis C", link: "https://liver.org.au/your-liver/liver-diseases/hepatitis-c/" }],
  24003: [{title: "IDEAL care trial Liver Health patient information sheet", link: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/IDEAL+Care+pt+resource+v1.5_FINAL_LF_approved.pdf"},
    { title: "Lifestyle recommendations for liver health ", link: "https://liver.org.au/living-well/" },
    { title: "What is a fibroscan?", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Fibroscan/FibroScan_2022_APPROVED.pdf" },
    { title: "What is fatty liver disease?", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Fatty%20Liver/Fatty%20Liver%20Disease%202021%20final.pdf" },
    { title: "Treatment for  hepatitis B", link: "https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Treatment%20for%20Hepatitis%20B/Treatment%20for%20Hepatitis%20B_2022_APPROVED.pdf " },
    { title: "Hepatitis C", link: "https://liver.org.au/your-liver/liver-diseases/hepatitis-c/" }],
  114000:[{title: "NHS unintended weight loss explained for patients", link: "https://www.nhs.uk/conditions/unintentional-weight-loss/#:~:text=Unintentional%20weight%20loss%20is%20when,a%20GP%20just%20in%20case" }],
  114001:[{title: "NHS unintended weight loss explained for patients", link: "https://www.nhs.uk/conditions/unintentional-weight-loss/#:~:text=Unintentional%20weight%20loss%20is%20when,a%20GP%20just%20in%20case" }],
  114002:[{title: "NHS unintended weight loss explained for patients", link: "https://www.nhs.uk/conditions/unintentional-weight-loss/#:~:text=Unintentional%20weight%20loss%20is%20when,a%20GP%20just%20in%20case" }],
  114003:[{title: "NHS unintended weight loss explained for patients", link: "https://www.nhs.uk/conditions/unintentional-weight-loss/#:~:text=Unintentional%20weight%20loss%20is%20when,a%20GP%20just%20in%20case" }],
  114004:[{title: "NHS unintended weight loss explained for patients", link: "https://www.nhs.uk/conditions/unintentional-weight-loss/#:~:text=Unintentional%20weight%20loss%20is%20when,a%20GP%20just%20in%20case" }],
  114005:[{title: "NHS unintended weight loss explained for patients", link: "https://www.nhs.uk/conditions/unintentional-weight-loss/#:~:text=Unintentional%20weight%20loss%20is%20when,a%20GP%20just%20in%20case" }],
  114006:[{title: "NHS unintended weight loss explained for patients", link: "https://www.nhs.uk/conditions/unintentional-weight-loss/#:~:text=Unintentional%20weight%20loss%20is%20when,a%20GP%20just%20in%20case" }],
  
  25000: [
    {title: "Understanding my low back pain", link: "https://www.mq.edu.au/__data/assets/pdf_file/0012/1023123/Digital-booklet.pdf"},
    {title: "Managing low back pain", link: "https://aci.health.nsw.gov.au/__data/assets/pdf_file/0010/553861/ACI-SIRA-Managing-low-back-pain-information-for-patients.pdf"},
    {title: "Acute short-term low back pain", link: "https://www.choosingwisely.org.au/resources/consumers-and-carers/acute-short-term-low-back-pain"},
    ],
  26000:[{title: "Your health after gestational diabetes", link: "https://www.ndss.com.au/about-diabetes/gestational-diabetes/after/" }], 
  26001:[{title: "Your health after gestational diabetes", link: "https://www.ndss.com.au/about-diabetes/gestational-diabetes/after/" }],
  26002:[{title: "Your health after gestational diabetes", link: "https://www.ndss.com.au/about-diabetes/gestational-diabetes/after/" }],
  31004:[{title: "NHS unintended weight loss explained for patients", link: "https://www.nhs.uk/conditions/unintentional-weight-loss/#:~:text=Unintentional%20weight%20loss%20is%20when,a%20GP%20just%20in%20case" }],

  33000: [{title: "Jean Hailes: Menopausal hormone therapy", link: "https://www.jeanhailes.org.au/resources/menopausal-hormone-therapy"}],
  33001: [{title: "Jean Hailes: Osteoporosis and osteopaenia", link: "https://www.jeanhailes.org.au/health-a-z/bone-health/osteoporosis-and-osteopaenia"}],
  33002: [{title: "Jean Hailes: Osteoporosis and osteopaenia", link: "https://www.jeanhailes.org.au/health-a-z/bone-health/osteoporosis-and-osteopaenia"}],
},
"RecommendationGuidelineLinks": {
  "Ckd_Rec_AtRisk" : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=9","https://kidney.org.au/"],
  "Ckd_Rec_Possible" : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=9","https://kidney.org.au/"],
  "Ckd_Rec_CdCkd" : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=9","https://kidney.org.au/"],
  "Ckd_Rec_InitiateAceArb" : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=46","https://kidney.org.au/"],
  "Ckd_Rec_IntensifyAceArb" : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=46","https://kidney.org.au/"],
  "Ckd_Rec_InitiateStatin" : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=75","https://kidney.org.au/"],
  "Ckd_Rec_IntensifyStatin" : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=75","https://kidney.org.au/"],
  "Ckd_Rec_InitiateAceArbBpOk" : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=46","https://kidney.org.au/"],
  "PrC_Rec_RepeatPSAOrderFT" : ["https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf", "https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"],
  "PrC_Rec_FTPSAOnly" : ["https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf", "https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"],
  "PrC_Rec_ReferToUrologist" : ["https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf", "https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"],
  "PrC_Rec_ReviewPatientsTest" : [],
  "PltC_Rec_MaleRepeatPltCount": ["https://doi.org/10.1056/NEJMra035363", "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf", ".  https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"],
  "PltC_Rec_FemaleRepeatPltCount": ["https://doi.org/10.1056/NEJMra035363", "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf", ".  https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"],  
  "PltC_Rec_MaleTwoConsecElevatedPltCount":  ["https://doi.org/10.1056/NEJMra035363", "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf", ".  https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"],
  "PltC_Rec_FemaleTwoConsecElevatedPltCount":  ["https://doi.org/10.1056/NEJMra035363", "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf", ".  https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"],  
  "PltC_Rec_ReviewPatientsTest":  [], 
},
"RecommendationGuidelineLinksById": {
  1 : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=9","https://kidney.org.au/"],
  2 : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=9","https://kidney.org.au/"],
  3 : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=9","https://kidney.org.au/"],
  4 : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=46","https://kidney.org.au/"],
  5 : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=46","https://kidney.org.au/"],
  6 : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=75","https://kidney.org.au/"],
  7 : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=75","https://kidney.org.au/"],
  8 : ["https://kidney.org.au/uploads/resources/chronic-kidney-disease-management-in-primary-care-4th-edition-handbook_2020-07-16-064708.pdf#page=46","https://kidney.org.au/"],
  19 : ["https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf", "https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"],
  20 : ["https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf", "https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"],
  21 : ["https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf", "https://www.prostate.org.au/awareness/for-healthcare-professionals/clinical-practice-guidelines-on-psa-testing/"],
  22 : [],
  24 : ["https://doi.org/10.1056/NEJMra035363", "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf", ".  https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"],
  25 : ["https://doi.org/10.1056/NEJMra035363", "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf", ".  https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"],
  26 : ["https://doi.org/10.1056/NEJMra035363", "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf", ".  https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"],
  27 : ["https://doi.org/10.1056/NEJMra035363", "https://www.nice.org.uk/guidance/ng12/chapter/Recommendations-organised-by-symptom-and-findings-of-primary-care-investigations", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_lung_cancer_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_oesophageal_gastric_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_colorectal_resource_card_online.pdf", ".  https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_endometrial_cancers_resource_card_online.pdf", "https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_ovarian_cancers_resource_card_online.pdf"],
  28 : [],
},

  'analysisTitle' : {
    'Ckd_Rec_AtRisk' : 'Analysis for At-Risk of CKD Recommendation',
    'Ckd_Rec_Possible' : 'Analysis for Possible diagnosis of CKD recommendation',
    'Ckd_Rec_CdCkd' : 'Analysis for recommending coding of diagnosed CKD',
    'Ckd_Rec_InitiateAceArb' : 'Analysis for initiation of ACE or ARB recommendation',
    'Ckd_Rec_IntensifyAceArb' : 'Analysis for intensification of ACE or ARB recommendation',
    'Ckd_Rec_InitiateStatin' : 'Analysis for initiation of Statin recommendation',
    'Ckd_Rec_IntensifyStatin' : 'Analysis for intensification of Statin recommendation',
    'Ckd_Rec_InitiateAceArbBpOk' : 'Analysis for initiation of ACE or ARB recommendation',
    'PrC_Rec_RepeatPSAOrderFT' : 'Analysis for At-Risk of Prostate Cancer Recommendation',
    'PrC_Rec_FTPSAOnly' : 'Analysis for At-Risk of Prostate Cancer Recommendation',
    'PltC_Rec_MaleRepeatPltCount' : 'Analysis for At-Risk of Platelet Elevated Cancer',
    'PltC_Rec_FemaleRepeatPltCount' : 'Analysis for At-Risk of Platelet Elevated Cancer',
    'PltC_Rec_MaleTwoConsecElevatedPltCount' : 'Analysis for At-Risk of Platelet Elevated Cancer',
    'PltC_Rec_FemaleTwoConsecElevatedPltCount' : 'Analysis for At-Risk of Platelet Elevated Cancer',
    'PltC_Rec_ReviewPatientsTest' : 'Analysis for At-Risk of Platelet Elevated Cancer',
  },
   'analysisTitleById' : {
    1 : 'Analysis for At-Risk of CKD Recommendation',
    2 : 'Analysis for Possible diagnosis of CKD recommendation',
    3 : 'Analysis for recommending coding of diagnosed CKD',
    4 : 'Analysis for initiation of ACE or ARB recommendation',
    5 : 'Analysis for intensification of ACE or ARB recommendation',
    6 : 'Analysis for initiation of Statin recommendation',
    7 : 'Analysis for intensification of Statin recommendation',
    8 : 'Analysis for initiation of ACE or ARB recommendation',
    19 : 'Analysis for At-Risk of Prostate Cancer Recommendation',
    20 : 'Analysis for At-Risk of Prostate Cancer Recommendation',
    21 : 'Analysis for At-Risk of Prostate Cancer Recommendation',
    22 : 'Analysis for At-Risk of Prostate Cancer Recommendation',
    24 : 'Analysis for At-Risk of Platelet Elevated Cancer',
    25 : 'Analysis for At-Risk of Platelet Elevated Cancer',
    26 : 'Analysis for At-Risk of Platelet Elevated Cancer',
    27 : 'Analysis for At-Risk of Platelet Elevated Cancer',
    28 : 'Analysis for At-Risk of Platelet Elevated Cancer',
  },

risks: {
  1: {
    1068: {'text': ''},
    43 : {'text': ""},
    42 : {'text': ""},
    39 : {'text': ""},
    36 : {'text': ""},
    46 : {'text': ""},
    44 : {'text': ""},
    21 : {'text': ""},
    22 : {'text': ""},
    24 : {'text': ""},
    6 : {'text': ""},
    7 : {'text': ""},
    25 : {'text': ""}
  },
  2: {
    1068: {'text': ''},
    17 : {'text': ''},
    15 : {'text': ''},
    14 : {'text': ''},
  },
  3: {
    1068: {'text': ''},
    17 : {'text': ''},
    15 : {'text': ''}
  },
  4: {
    1052: {'text': ''},
    1053: {'text': ''},
    6 : {'text': ''},
    7 : {'text': ''},
    10 : {'text': ''},
    11 : {'text': ''},
    12 : {'text': ''},
    13 : {'text': ''},
  },
  5: {
    1052: {'text': ''},
    1067: {'text': ''},
    6 : {'text': ''},
    7 : {'text': ''},
    10 : {'text': ''},
    11 : {'text': ''},
    12 : {'text': ''},
    13 : {'text': ''},
  },
  6: {
    1051: {'text': ''},
    1052: {'text': ''},
    3:{'text': ''},
    1063:{'text': ''},
    1062:{'text': ''},
  },
  7: {
    // 1 : {'text': 'Diagnosed with CKD'},
    // 'STATIN' : {'text': 'Already on Statins'},
    1052 : {'text': 'Diagnosed with CKD'},
    1050: {'text': 'Already on Statins'},
    18 : {'text': 'TC >= 4.0 mmol/L'},
    19 : {'text': 'LDL-C > 2.0 mmol/L'},
    20 : {'text': 'TG > 2.0 mmol/L'}
  },
  8: {
    1052: {'text': ''},
    1053: {'text': ''},
    1054: {'text': ''},
    1055: {'text': ''},
    1056: {'text': ''},
    1057: {'text': ''},
    1058: {'text': ''},
    1059: {'text': ''},
    1060: {'text': ''},
    1061: {'text': ''},
    // 42 : {'text': 'Coded hypertension'},
    // 8 : {'text': '2 recent Systolic blood pressure< = 140'},
    // 9 : {'text': '2 recent Diastolic blood pressure <= 90'},
    // 26 : {'text': 'Framingham 5 year risk > 15%'}
  },
  19:{
    50 : {'text': '40-49 with most recent PSA result in the last 6 months ABNORMAL (> 2.0 ng/ml)'},
    51 : {'text': '50-80 with most recent PSA result in the last 6 months ABNORMAL (> 3.0 ng/ml)'},
    52: {'text': 'There is no other PSA test in the last 6 months'},
    53: {'text': 'There is a previous test result in the last 6 months, but the result is NORMAL'},
    54: {'text': 'There are TWO ABNORMAL PSA tests in the last 6 months, but the most recent PSA does not have a F/T PSA to match it.'},
  },
  20:{
    55 : {'text': '40-49 with last PSA result within 3 months and previous PSA result in the last 6 months – both results ABNORMAL (> 2.0 ng/ml)'},
    56 : {'text': '50-80 with last PSA result within 3 months and previous PSA result in the last 6 months – both results ABNORMAL (> 3.0 ng/ml)'},
    57 : {'text': 'No F/T test to match most recent PSA test '},
  },
  21:{
    58 : {'text': 'The most recent Free-to-Total PSA % test is ABNORMAL (< 25%)'},
    59 : {'text': 'The most recent PSA test is EXTREME (> 5.5 ng/ml)'},
    60 : {'text': '40-49 Years old and two PSA tests in the last 6 months are ABNORMAL (> 2.0 ng/ml)'},
    61 : {'text': '50-80 Years old and two PSA tests in the last 6 months are ABNORMAL (> 3.0 ng/ml)'},
  },
  22:{
    62 : {'text': 'Most recent PSA result in the last 6 months has invalid Unit of Measure'},
    63 : {'text': 'Most recent PSA result in the last 6 months has a result out of expected range (0 - 100)'},
    64 : {'text': 'Previous PSA result in the last 6 months has invalid Unit of Measure'},
    65 : {'text': 'Previous PSA result in the last 6 months has a result out of expected range (0 - 100)'},
    66 : {'text': 'Most recent F/T result has invalid Unit of Measure'},
    67 : {'text': 'Most recent F/T result has a result out of expected range (0 – 100)'},
  },
  24:{
    68 : {'text': '40+ years old male with most recent (<6month old) platelet count elevated. No previous elevated test <12mths'},
  }, 
  25:{
    69 : {'text': '40+ years old female with most recent (<6month old) platelet count elevated. No previous elevated test <12mths'},
  }, 
  26:{
    70 : {'text': '40+ years old male with two elevated platelet counts <12 months old.'},
  }, 
  27:{
    71 : {'text': '40+ years old female with two elevated platelet counts <12 months old.'},
  },
  28:{
    72: { 'text': 'Invalid Test Result data – Invalid Unit of Measure or result value for either of the most recent 2 tests' },
  },
  40: {
    1070: { 'text': '' },
    1071: { 'text': '' },
    1072: { 'text': '' },
    1073: { 'text': '' },
    1074: { 'text': '' },
    1075: { 'text': '' },
    1076: { 'text': '' },
    1086: { 'text': '' },
    1077: { 'text': '' },
    1078: { 'text': '' },
  },
  41: {
    1070: { 'text': '' },
    1071: { 'text': '' },
    1072: { 'text': '' },
    1073: { 'text': '' },
    1074: { 'text': '' },
    1079: { 'text': '' },
    1080: { 'text': '' },
    1081: { 'text': '' },
    1082: { 'text': '' },
    1086: { 'text': '' },
    1083: { 'text': '' },
    1084: { 'text': '' },
  },
  45: {
    6: { 'text': '' },
    7: { 'text': '' },
    21: { 'text': '' },
    22: { 'text': '' },
    24: { 'text': '' },
    25: { 'text': '' },
    36: { 'text': '' },
    39: { 'text': '' },
    1093: { 'text': '' },
    1092: { 'text': '' },
    2: { 'text': '' },
    46: { 'text': '' },
    1091: { 'text': '' },
  },
  4000:{
    4000: {'text': '50+ years old without a gastrointestinal or endometrial (if female) or unspecific/metastatic cancer diagnosis, and with marker of anaemia/iron deficiency in the last 6 months, and without a previous marker of anaemia/iron deficiency in the last 12 months'},
    4001: {'text': 'Male and Hb < 130 g/l'},
    4002: {'text': 'Female and Hb < 115 g/l'},
    4003: {'text': 'MCV <80 fL'},
    4004: {'text': 'MCH <27 pg'},
    4005: {'text': 'Ferritin<30 µg/L'},
  },
  4001:{
    4006: {'text': '50+ male without a gastrointestinal or unspecific/metastatic cancer diagnosis in the last 5 years and with 2 markers of anaemia/iron deficiency in the last 12 months'},
    4001: {'text': 'Male and Hb < 130 g/l'},
    4003: {'text': 'MCV <80 fL'},
    4004: {'text': 'MCH <27 pg'},
    4005: {'text': 'Ferritin<30 µg/L'},
  },
  4002:{
    4007: {'text': '50+ female without a gastrointestinal or endometrial or unspecific/metastatic cancer diagnosis in the last 5 years and with 2 markers of anaemia/iron deficiency in the last 12 months'},
    4002: {'text': 'Female and Hb < 115 g/l'},
    4003: {'text': 'MCV <80 fL'},
    4004: {'text': 'MCH <27 pg'},
    4005: {'text': 'Ferritin<30 µg/L'},
  },
  4003:{
    4007: {'text': '50+ female without a gastrointestinal or endometrial or unspecific/metastatic cancer diagnosis in the last 5 years and with 2 markers of anaemia/iron deficiency in the last 12 months'},
    4001: {'text': 'Male and Hb < 130 g/l'},
    4002: {'text': 'Female and Hb < 115 g/l'},
    4003: {'text': 'MCV <80 fL'},
    4004: {'text': 'MCH <27 pg'},
    4005: {'text': 'Ferritin<30 µg/L'},
  },  
  6000: {
    25: {'text': 'First Nations and age >= 30'},
    5000: {'text': 'Adults aged ≥ 45 years'},
    5001: {'text': 'No recent MBS Billing for Healthy Heart check, Health Assessment or Health Assessment for First Nations People'}
  },  
  6001: {
    5006: {'text': ''}
  },  
  6002: {
    5003: {'text': ''},
  },    
  6003: {
    5012: {'text': ''},
  },     
  6004: {
    5013: {'text': ''},
  },      
  6005: {
    18: {'text': ''},
    5017: {'text': ''},
    5018: {'text': ''},
    41: {'text': ''},
  },   
  6006: {
    5016: {'text': ''},
    5019: {'text': ''},
    5020: {'text': ''},
    10: {'text': ''},
    11: {'text': ''},
    12: {'text': ''},
    13: {'text': ''},
  },
  6008: {
    5015: {'text': ''}
  },
  6014: {
    // 5023 is not in the DB currently. 
    // 5023: {'text': ''},
    5005: {'text': ''},
    5007: {'text': ''},
    5014: {'text': ''},
    5023:{},
  },
  6013:{
    5054: {'text': ''},
    5022: {'text': ''},
    5021: {'text': ''}
  },
  6015:{
    5024: {'text': 'No diagnosis of hypertension'},
    5019: {'text': '2 most recent consecutive Systolic blood pressure (SBP) >= 140'},
    5020: {'text': '2 most recent consecutive Diastolic blood pressure (DBP) >= 90'},
    5048: {'text': 'Diabetes/Albuminuria and BP not at target > 130/80'},
  },
  6018:{
    5025: {'text': ''},
    5026: {'text': ''},
    5029: {'text': ''},
    5030: {'text': ''},
    5031: {'text': ''},
    5032: {'text': ''},
    5033: {'text': ''},
    5034: {'text': ''}
  },
  6020:{
    5035: {'text': ''},
    5036: {'text': ''},
    5037: {'text': ''},
    5038: {'text': ''},
    5039: {'text': ''},
    5040: {'text': ''}
  },
  6021:{
    5035: {'text': ''},
    5041: {'text': ''},
    5053: {'text': ''},
    5042: {'text': ''},
    5043: {'text': ''},
    5044: {'text': ''},
    5049: {'text': ''}
  },
  6024:{
    5050: {'text': ''},
    1053: {'text': ''}
  },
  6025:{
    5050: {'text': ''},
    5051: {'text': ''}
  },
  6027:{
    5050: {'text': ''},
    5052: {'text': ''}
  },
  6028:{
    5047: {'text': ''}
  },
  5002:{
    9017 : {'text': '18-75 years old, AD medication prescription for more than 12mths, and not currently prescribed antipsychotics, other AD or mood stabilisers.'},
  },
  5003:{
    9016 : {'text': '50-64 years old, presented within the last 18 months with potentially new onset psychiatric, cognitive, neurological, and/or behavioural and personality changes/symptoms.'},
  },
  9004: {
    7000: {'text':''},
    7093: {'text':''},
    7091: {'text':''},
    6: {'text':'Two most recent consecutive Systolic blood pressure (SBP) > 140'},
    7: {'text':'Two most recent consecutive Diastolic blood pressure (DBP) > 90'},    
    7089: {'text':''},
    //39: {'text':'Coded diagnosis cardiovascular disease'},
    7011: {'text':'Two most recent consecutive Systolic blood pressure (SBP) > 130'},
    7012: {'text':'Two most recent consecutive Diastolic blood pressure (DBP) > 80'},
    7047: {'text':'Coded diagnosis cardiovascular disease'},
  },
  9005: {
    7093: {'text':''},
    7091: {'text':''},
    7000: {'text':'Coded diagnosis “Type 2 diabetes”'},
    7047: {'text':'Coded diagnosis cardiovascular disease'},
    7048: {'text':'Calculated high cardiovascular disease risk (Framingham)'},
    7013: {'text':'Age >60 years'},
    16: {'text':'estimated glomerular filtration rate [eGFR] <45'},
    1049: {'text':'Coded diagnosis familial hypercholesterolaemia'},
    7014: {'text':'Serum total cholesterol (TC) >7.5 mmol/L'},
    7089: {'text':''},
    18: {'text':'Total Cholesterol >=4.0 mmol/L'},
    7046: {'text':'LDL-C >=2.0 mmol/L'},
    5018: {'text':'Triglycerides >=2 mmol/L'},
    7017: {'text':'HDL-C <1.0 mmol/L'},
    7049: {'text':'SBP >= 180'},
    7050: {'text':'DBP >= 110'}
  },
  9000: {
    7092: {'text':''},
    7091: {'text':''},
    7021: {'text':'Age >= 40 and  Body mass index (BMI) >=30 kg/m2'},
    7090: {'text':''},
    7022: {'text':'High risk ethnicity'},
    7047: {'text':'Coded diagnosis cardiovascular disease'},
    5008: {'text':'Coded diagnosis ischemic stroke'},
    7023: {'text':'Coded diagnosis Gestational Diabetes and not currently pregnant'},
    7005: {'text':'Coded Diagnosis Polycystic Ovarian Syndrome'},
    7020: {'text':'Coded diagnosis prediabetes'},
    7018: {'text':'Current antipsychotic use'},
    7052: {'text':'Age >=40 and Coded diagnosis hypertension'},
    7024: {'text':'Age >=40 and 2 most recent consecutive Systolic blood pressure (SBP) > 140'},
    7025: {'text':'Age >=40 and 2 most recent consecutive Diastolic blood pressure (DBP) > 90'},
    7026: {'text':'Most recent Fasting Blood Glucose >= three years'},
    7027: {'text':'Most recent HbA1c >= three years'},
    7004: {'text':'Coded diagnosis “Impaired fasting glycemia'},
    7003: {'text':'Coded diagnosis “Impaired glucose tolerance'},
    7020: {'text':''},
    7030: {'text':'Most recent Fasting Blood Glucose >= one year'},
    7031: {'text':'Most recent HbA1c >= one year'},
    7028: {'text':'Most recent Fasting glucose >=6.1 mmol/L but <=6.9 mmol/L up to a maximum of 3 years from the current date.'},
    7029: {'text':'Most recent HbA1c >=6% BUT <6.5% up to a maximum of 3 years from the current date'},
    7032: {'text':'AUSDRISK>=12'},
    7033: {'text':'Current oral glucocorticoid daily >= 30 days'}
  },
  9002: {
    7092: {'text':''},
    7091: {'text':''},
    7034: {'text':'Most recent TWO fasting blood glucose >= 7 mmol/L'},
    7035: {'text':'Most recent TWO random blood glucose >= 11.1 mmol/L'},
    7036: {'text':'Most recent TWO HbA1c >= 6.5 %'},
    7039: {'text':'A combination of the following exists, which occurs more than 1 day apart;\nMost recent fasting blood glucose test >=7mmol/L,\nMost recent random blood glucose >= 11.1 mmol/L\nMost recent HbA1c >= 6.5 %'},
  },
  9003: {
    7093: {'text':''},
    7091: {'text':''},
    7051: {'text':'Patient has a coded diagnoses of Type 2 Diabetes'},
    7040: {'text':'Most recent HbA1c >= 7 %'},
  },
  9001: {
    7092: {'text':''},
    7091: {'text':''},
    7041: {'text':'Most recent fasting blood glucose >= 7 mmol/L'},
    7042: {'text':'Most recent random blood glucose >= 11.1 mmol/L'},
    7043: {'text':'Most recent HbA1c >= 6.5 %'},
  },  
  9018: {
    7093: {'text':''},
    7091: {'text':''},
    7000: {'text':''},
    7062: {'text':''},
    7068: {'text':''},
    1: {'text':''},
    9014: {'text':''},
    7086: {'text':''},
    7087: {'text':''},
    7088: {'text':''},
    7077: {'text':''},
    7078: {'text':''},
    7079: {'text':''},
    7080: {'text':''},
    7081: {'text':''},
    7082: {'text':''},
    7083: {'text':''},
    7084: {'text':''},
    7085: {'text':''},
  },
  12000: {
    8000: {'text': ''},
    8001: {'text': ''},
    8002: {'text': ''},
    8003: {'text': ''},
    8004: {'text': ''},
    8005: {'text': ''},
    8006: {'text': ''},
    8012: {'text': ''},
    8013: {'text': ''},
    8014: {'text': ''},
    8015: {'text': ''},
    8016: {'text': ''},
    8017: {'text': ''},
    8018: {'text': ''},
    8019: {'text': ''},
    8020: {'text': ''},
    8021: {'text': ''},
  },
  13000: {
    9000: {'text': ''},
    9001: {'text': ''},
    9002: {'text': ''},
    9003: {'text': ''},
    9008: {'text': ''},
    9009: {'text': ''},
    7000: {'text': ''},
    7001: {'text': ''},
  },
  13001: {
    1: {'text': ''},
    9000: {'text': ''},
    9001: {'text': ''},
    9005: {'text': ''},
    9009: {'text': ''},
    9011: {'text': ''},
    7037: {'text': ''},
    7007: {'text': ''},
    7058: {'text': ''},
    7059: {'text': ''},
    7060: {'text': ''},
  },
  14000: {
    10042: {'text': ''},
    10043: {'text': ''},
    10044: {'text': ''},
    10045: {'text': ''},
    10046: {'text': ''},
    10047: {'text': ''},
  },
  15000: {
    11001: {'text': ''},
    11002: {'text': ''},
    11010: {'text': ''},
    
  },
  16000: {
    10048: {'text': ''},
    
  },
  16001: {
    10049: {'text': ''},
    
  },
  17000: {
    13003: {'text': ''},
    13004: {'text': ''},
    13005: {'text': ''},
    13006: {'text': ''},
    13007: {'text': ''},
    13008: {'text': ''},
    13009: {'text': ''},
    13010: {'text': ''},
    13011: {'text': ''},
  },
  17001: {
    13003: {'text': ''},
    13004: {'text': ''},
    13005: {'text': ''},
    13006: {'text': ''},
    13007: {'text': ''},
    13018: {'text': ''},
    13021: {'text': ''},
    13012: {'text': ''},
    13013: {'text': ''},
    13014: {'text': ''},
    13015: {'text': ''},
    13016: {'text': ''},
    13017: {'text': ''},
  },
  17002: {
    13003: {'text': ''},
    13004: {'text': ''},
    13005: {'text': ''},
    13006: {'text': ''},
    13007: {'text': ''},
    13020: {'text': ''},
    13021: {'text': ''},
    13019: {'text': ''},
    13015: {'text': ''},
    13016: {'text': ''},
    13017: {'text': ''},
  },
  19000: {
    12000: {'text': ''},
    12001: {'text': ''},
    12002: {'text': ''},    
    12007: {'text': ''},
    12003: {'text': ''},
    12004: {'text': ''},
    12005: {'text': ''},
    12006: {'text': ''},
    
  },
  20000: {
    14024: {'text': ''},
    14025: {'text': ''},
    14026: {'text': ''},
    14029: {'text': ''},
    
  },
  20001: {
    14024: {'text': ''},
    14025: {'text': ''},
    14027: {'text': ''},
    14030: {'text': ''},
    
  },
  20002: {
    14028: {'text': ''},
    14031: {'text': ''},
    14024: {'text': ''},
    14025: {'text': ''},
    
  },
  20003: {
    14033: {'text': ''},
    14032: {'text': ''},
    14024: {'text': ''},
    14034: {'text': ''},
    
  },
  22001: {
    16006: {'text': ''},
    16013: {'text': ''},
  },
  23000: {
    17003: {'text': ''},
    17004: {'text': ''},
    17005: {'text': ''},
  },
  23001: {
    17003: {'text': ''},
    17006: {'text': ''},
  },
  24002: {
    19003: {'text': ''},
    19006: {'text': ''},
    19007: {'text': ''},
    19008: {'text': ''},
    19009: {'text': ''},
    19010: {'text': ''},
    19011: {'text': ''},
    19012: {'text': ''}
  },
  24003: {
    19005: {'text': ''},
    19006: {'text': ''},
    19007: {'text': ''},
    19008: {'text': ''},
    19009: {'text': ''},
    19010: {'text': ''},
    19011: {'text': ''},
    19012: {'text': ''}
  },
  25000: {
    20002: {'text': ''},
  },
  201:{
    73 : {'text': 'Algorithm trigger example.'},
  },
  202:{
    73 : {'text': 'Algorithm trigger example.'},
  },
  203:{
    73 : {'text': 'Algorithm trigger example.'},
  },
  204:{
    73 : {'text': 'Algorithm trigger example.'},
  },
  205:{
    73 : {'text': 'Algorithm trigger example.'},
  },
  50000: {
    50001: {'text': ''},
    50002: {'text': ''},
    50003: {'text': ''},
  },
  50001: {
    50001: {'text': ''},
    50002: {'text': ''},
    50003: {'text': ''},
  },
  114000: {
    18002: {'text': ''},
    18003: {'text': ''},
  },
  114001: {
    18031: {'text': ''},
    18015: {'text': ''},
    18016: {'text': ''},
    18017: {'text': ''},
    18018: {'text': ''},
    18019: {'text': ''},
    18020: {'text': ''},
    18021: {'text': ''},
    18022: {'text': ''},
    18023: {'text': ''},
    18024: {'text': ''},
    18025: {'text': ''},
    18026: {'text': ''},
    18027: {'text': ''},
    18029: {'text': ''},
    18030: {'text': ''},
  },    
  114002: {
    18002: {'text': ''},
  },  
  114003: {
    18002: {'text': ''},
  },  
  114004: {
    18002: {'text': ''},
  },  
  114005: {
    18002: {'text': ''},
  },  
  114006: {
    18002: {'text': ''},
  },  
  26000: {
    21000: {'text': ''},
    21002: {'text': ''},
  },
  26001: {
    21000: {'text': ''},
    21001: {'text': ''},
    21007: {'text': ''},
    21008: {'text': ''},
    21009: {'text': ''},
    21010: {'text': ''},
    21011: {'text': ''},
  },
  26002: {
    21000: {'text': ''},
    21002: {'text': ''},
    21012: {'text': ''},
    21013: {'text': ''},
    21014: {'text': ''},
  },
  31000: {
    31000: {'text': ''},
    31001: {'text': ''},
    31002: {'text': ''},
    31004: {'text': ''},
    31005: {'text': ''},
  },
  31001: {
    31000: {'text': ''},
    31001: {'text': ''},
    31003: {'text': ''},
    31006: {'text': ''},
    31005: {'text': ''},
  },
  31004: {
    31010: {'text': ''},
    31011: {'text': ''},
  },
  33000: {
    33000: {'text': ''},
    33001: {'text': ''},
    33002: {'text': ''},
    33003: {'text': ''},
    33004: {'text': ''},
    33005: {'text': ''},
    33006: {'text': ''},
    33007: {'text': ''},
    33008: {'text': ''},
    33009: {'text': ''},
    33010: {'text': ''},
    33011: {'text': ''},
  },
  33001: {
    33018: {'text': ''},
    33012: {'text': ''},
    33013: {'text': ''},
    33008: {'text': ''},
    33014: {'text': ''},
    33015: {'text': ''},
    33016: {'text': ''},
    33017: {'text': ''},
  },
  33002: {
    33025: {'text': ''},
    33019: {'text': ''},
    33020: {'text': ''},
    33002: {'text': ''},
    33021: {'text': ''},
    33004: {'text': ''},
    33022: {'text': ''},
    33023: {'text': ''},
    33024: {'text': ''},
  },
},

  "RecommendationCategoriesRiskMapping":{
      "Ckd_Rec_AtRisk" : "Ckd_Rec_AtRisk",
      "Ckd_Rec_Possible" : "Ckd_Rec_Possible",
      "Ckd_Rec_CdCkd" : "Ckd_Rec_CdCkd",
      
      "Ckd_Rec_InitiateAceArb" : "Ckd_Rec_InitiateAceArb",
      "Ckd_Rec_InitiateAceArbBpOk" : "Ckd_Rec_InitiateAceArbBpOk",        
      "Ckd_Rec_IntensifyAceArb" : "Ckd_Rec_IntensifyAceArb",
      "Ckd_Rec_InitiateStatin" : "Ckd_Rec_InitiateStatin",
      "Ckd_Rec_IntensifyStatin" : "Ckd_Rec_IntensifyStatin",  

      "Ckd_Trt_InitiateAceArb" : "Ckd_Trt_InitiateAceArb",
      "Ckd_Trt_InitiateAceArbBpOk" : "Ckd_Trt_InitiateAceArbBpOk",        
      "Ckd_Trt_IntensifyAceArb" : "Ckd_Trt_IntensifyAceArb",
      "Ckd_Trt_InitiateStatin" : "Ckd_Trt_InitiateStatin",
      "Ckd_Trt_IntensifyStatin" : "Ckd_Trt_IntensifyStatin",  
      "PrC_Rec_RepeatPSAOrderFT" : "PrC_Rec_RepeatPSAOrderFT",
      "PrC_Rec_FTPSAOnly" : "PrC_Rec_FTPSAOnly",
      "PrC_Rec_ReferToUrologist" : "PrC_Rec_ReferToUrologist",
      "PrC_Rec_ReviewPatientsTest" : "PrC_Rec_ReviewPatientsTest",

      "PltC_Rec_RepeatPltCount" : "PltC_Rec_RepeatPltCount",
      "PltC_Rec_TwoConsecElevatedPltCount" : "PltC_Rec_TwoConsecElevatedPltCount",
      "PltC_Rec_ReviewPatientsTest" : "PltC_Rec_ReviewPatientsTest",
      
      "cdCVD" : "13",	
      "cdDiabetes" : "12",
      "cdProstateCancer" : "23",
      "cdGastEndoCancer" : "4004",
  },

  'Ckd_Rec_AtRisk' : {
    'CDDIABETES' : {'text': "Diabetes"},
    'CDHYPERTENSION' : {'text': "Hypertension"},
    'CDCVD' : {'text': "Cardiovascular disease"},
    'BMI>=30' : {'text': "BMI >= 30"},
    'SMOKER' : {'text': "Current Smoker"},
    'CDACUTEKIDNEYINJURY' : {'text': "Acute Kidney Injury"},
    'BSLF7' : {'text': "2 consecutive BSLF >=7"},
    'BSL11_1' : {'text': "2 consecutive BSL >= 11.1"},
    '2HBA1C6_5' : {'text': "2 consecutive HbA1c >= 6.5"},
    'SBP>140' : {'text': "2 consecutive SBP > 140"},
    'DBP>90' : {'text': "2 consecutive DBP > 90"},
    'IsATSI_30' : {'text': "First Nations and age >= 30"}
  },
  'Ckd_Rec_Possible' : {
    'EGFR<60' : {'text': 'Most Recent eGFR < 60 ml/min/1.73m2'},
    'ACR>=2.5Or3.5' : {'text': 'Male and Most Recent urine ACR >= 2.5, or Female and Most Recent urine ACR >= 3.5'},
    '2ABNORMAL_ACR_12MONTHS' : {'text': 'Male and Most Recent urine ACR < 2.5, or Female and Most Recent urine ACR < 3.5 && At least 2 Abnormal uACR results in the last 12 months'},
  },
  'Ckd_Rec_CdCkd' : {
    'EGFR<60' : {'text': 'Most recent eGFR, and another eGFR >= 3mths earlier are both < 60 ml/min/1.73m2'},
    'ACR>=2.5Or3.5' : {'text': 'Male and most recent urine ACR, and another urine ACR >= 3mths earlier are both >= 2.5, or Female and most recent urine ACR, and another urine ACR >= 3mths earlier are both >= 3.5'}
  },
  'Ckd_Rec_IntensifyStatin' : {
    'CKD' : {'text': 'Diagnosed with CKD'},
    'STATIN' : {'text': 'Already on Statins'},
    'TC>=4' : {'text': 'TC >= 4.0 mmol/L'},
    'LDL>2' : {'text': 'LDL-C > 2.0 mmol/L'},
    'TG>2' : {'text': 'TG > 2.0 mmol/L'}
  },
  'Ckd_Rec_InitiateStatin' : {
    'DIABETESAGE60' : {'text': 'Diabetes and age > 60'},
    'ACR>=2.5Or3.5' : {'text': 'Male and urine ACR > 2.5, or Female and urine ACR > 3.5'},
    'SBP>180' : {'text': 'Systolic BP >= 180 mmHg, Diastolic BP > 110 mmHg'},
    'CVDHIGHRISK' : {'text': 'Framingham 5 year risk > 15%'},
    // 'HIGHRISK_FRAMINGHAM5YEARS' : {'text': 'Framingham 5 year risk > 15%'},
    'EGFR<45' : {'text': 'eGFR < 45 ml/min/1.73m2;'},
    'TC>7.5' : {'text': 'Total Cholestrol > 7.5 mmol/L'},
    'PERS_PROTEINURA' : {'text': 'Moderate to severe chronic kidney disease'},
    'NOT_STATIN': {'text': 'Not on statin'},
    'FAMILY_CHOL': {'text': 'Familial Hypercholestrolaemia'}
  },
  'Ckd_Rec_IntensifyAceArb' : {
    // 'CDHYPERTENSION' : {
    //   'text': 'Coded hypertension'
    // },
    // 'CVDHIGHRISK' : {
    //   'text': 'Framingham 5 year risk > 15%',
    // },
    'SBP>140' : {
      'text': '2 most recent Systolic blood pressure > 140',
      'group': 'arb'
    },
    'DBP>90' : {
      'text': '2 most recent Diastolic blood pressure > 90',
      'group': 'arb'
    },
    // 'ACR>=2.5Or3.5' : {
    //   'text': 'Male and most recent urine ACR, and another urine ACR >= 3mths earlier are both >= 2.5, or Female and most recent urine ACR, and another urine ACR >= 3mths earlier are both >= 3.5',
    //   'group': 'ace'
    // },
    // 'ACR>=2.5Or3.5&&SBP>130' : {
    //   'text': '2 most recent Systolic blood pressure > 130',
    //   'group': 'ace' 
    // },
    // 'ACR>=2.5Or3.5&&DBP>80' : {
    //   'text': '2 most recent Diastolic blood pressure > 80',
    //   'group': 'ace'
    // },
    'ACR>=2.5Or3.5&&SBP>130' : {
      'text': 'Male and most recent urine ACR, and another urine ACR >= 3mths earlier are both >= 2.5, or Female and most recent urine ACR, and another urine ACR >= 3mths earlier are both >= 3.5 and 2 most recent Systolic blood pressure > 130',
      'group': 'ace' 
    },
    'ACR>=2.5Or3.5&&DBP>80' : {
      'text': 'Male and most recent urine ACR, and another urine ACR >= 3mths earlier are both >= 2.5, or Female and most recent urine ACR, and another urine ACR >= 3mths earlier are both >= 3.5 and 2 most recent Diastolic blood pressure > 80',
      'group': 'ace'
    },
    'DIA&&SBP>130' : {
      'text': 'Diabetes & 2 most recent Systolic blood pressure > 130',
      'group': 'ace1'
    },
    'DIA&&DBP>80' : {
      'text': 'Diabetes & 2 most recent Diastolic blood pressure > 80',
      'group': 'ace1'
    }
  },
  'Ckd_Rec_InitiateAceArb' : {
    'CDHYPERTENSION' : {
      'text': 'Coded hypertension'
    },
    'CVDHIGHRISK' : {
      'text': 'Framingham 5 year risk > 15%',
    },
    'SBP>140' : {
      'text': '2 most recent Systolic blood pressure > 140',
      'group': 'arb'
    },
    'DBP>90' : {
      'text': '2 most recent Diastolic blood pressure > 90',
      'group': 'arb'
    },
    'ACR>=2.5Or3.5' : {
      'text': 'Male and most recent urine ACR, and another urine ACR >= 3mths earlier are both >= 2.5, or Female and most recent urine ACR, and another urine ACR >= 3mths earlier are both >= 3.5',
      'group': 'ace'
    },
    'ACR>=2.5Or3.5&&SBP>130' : {
      'text': '2 most recent Systolic blood pressure > 130',
      'group': 'ace' 
    },
    'ACR>=2.5Or3.5&&DBP>80' : {
      'text': '2 most recent Diastolic blood pressure > 80',
      'group': 'ace'
    },
    'DIA&&SBP>130' : {
      'text': 'Diabetes & 2 most recent Systolic blood pressure > 130',
      'group': 'ace'
    },
    'DIA&&DBP>80' : {
      'text': 'Diabetes & 2 most recent Diastolic blood pressure > 80',
      'group': 'ace'
    }
  },
  'Ckd_Rec_InitiateAceArbBpOk' : {
    'CDHYPERTENSION' : {'text': 'Coded hypertension'},
    'SBP<=140' : {'text': '2 most recent Systolic blood pressure <= 140'},
    'DBP<=90' : {'text': '2 most recent Diastolic blood pressure <= 90'},
    'HIGHRISK_FRAMINGHAM5YEARS' : {'text': 'Framingham 5 year risk > 15%'}
  },
  'PrC_Rec_RepeatPSAOrderFT' : {
    'AGE_BETWEEN_40_49_ABNORMAL_PSA_SIX_MONTHS' : {'text': '40-49 with most recent PSA result > 2.0 ng/ml'},
    'AGE_OVER_50_ABNORMAL_PSA_SIX_MONTHS' : {'text': '50-80 with most recent PSA result > 3.0 ng/ml'},
  },
  'PrC_Rec_RepeatPSAOrderFT' : {
    'AGE_BETWEEN_40_49_ABNORMAL_PSA_THREE_MONTHS' : {'text': '40-49 with last PSA result within 3 months and > 2.0 ng/ml'},
    'AGE_OVER_50_ABNORMAL_PSA_THREE_MONTHS' : {'text': '50-80 with last PSA result within 3 months and > 3.0 ng/ml'},
    'ABNORMAL_PENULTIMATE_PSA_RESULT' : {'text': 'Abnormal previous PSA result'},
    'NO_RECENT_FTPSA_RESULT' : {'text': 'No recent F/T test'},
  },
  "PrC_Rec_ReferToUrologist" : {
    "PSA_GREATER_THAN_2p0" : {'text': 'PSA > 2.0 ng/ml'},
    "PSA_GREATER_THAN_3p0" : {'text': 'PSA > 3.0 ng/ml'},
    "MOST_RECENT_PSA_GREATER_THAN_5p5" : {'text': 'Most Recent PSA > 5.5 ng/ml'},
    "AGE_BETWEEN_40_TO_49" : {'text': '40-49 Years Old'},
    "AGE_GREATER_OR_EQUAL_TO_50" : {'text': '50-80 Years old'},
  },
  "PrC_Rec_ReviewPatientsTest":{
    "PSA_OR_FTPSA_INVALID_UNIT_OF_MEASURE" : {'text': 'Invalid PSA Test Result data – Unit of Measure'},
    "PSA_OR_FTPSA_OUT_OF_RANGE" : {'text': 'Invalid PSA Test Result data – Result out of expected range'},
  },
  "Cancer_Chk_PrC":{
    "PREVIOUS_PROSTATE_CANCER": {'text': "Previously diagnosed with prostate cancer"}
  },
  "PltC_Rec_MaleRepeatPltCount":{
    "MALE_AGE_40_OR_OVER_AND_MOST_RECENT_ELEVATED_PLT_COUNT_SIX_MONTHS_NO_PREVIOUS": {'text': "40+ years old male with most recent (<6month old) platelet count elevated. No previous elevated test <12mths"}
  },
  "PltC_Rec_FemaleRepeatPltCount":{
    "FEMALE_AGE_40_OR_OVER_AND_MOST_RECENT_ELEVATED_PLT_COUNT_SIX_MONTHS": {'text': "40+ years old female with most recent (<6month old) platelet count elevated. No previous elevated test <12mths"}
  },
  "PltC_Rec_MaleTwoConsecElevatedPltCount":{
    "MALE_AGE_40_OR_OVER_TWO_ELEVATED_PLT_COUNT_TWELVE_MONTHS": {'text': "40+ years old male with two elevated platelet counts <12 months old."}
  },
  "PltC_Rec_FemaleTwoConsecElevatedPltCount":{
    "FEMALE_AGE_40_OR_OVER_TWO_ELEVATED_PLT_COUNT_TWELVE_MONTHS": {'text': "40+ years old female with two elevated platelet counts <12 months old."}
  },
  "PltC_Rec_ReviewPatientsTest":{
    "PLT_COUNT_INVALID_UNIT_OF_MEASURE": {'text': "Invalid Test Result data – Invalid Unit of Measure or result value for either of the most recent 2 tests"}
  },
  'analysisResponseLabel' : {
    'Ckd_Rec_AtRisk' : 'A Kidney Health Check, consisting of eGFR, urine albumin:creatinine and blood pressure, is recommended.',
    'Ckd_Rec_Possible' : 'Require additional testing to confirm a diagnosis of CKD', 
    'Ckd_Rec_CdCkd' : 'Patient does not have a coded diagnosis recorded.',
    'Ckd_Rec_IntensifyAceArb' : 'This patient may have a blood pressure that is above current guideline recommendations. Blood pressure targets are ≤140/90mmHg or ≤130/80 in people with albuminuria or diabetes.',
    'Ckd_Rec_InitiateAceArb' : 'This patient may have a blood pressure that is above current guideline recommendations. Blood pressure targets are ≤140/90mmHg or ≤130/80 in people with albuminuria or diabetes.',
    'Ckd_Rec_IntensifyStatin' : 'Guidelines recommend that this patient commences a statin because they have a high CVD risk.',
    'Ckd_Rec_InitiateStatin' : "Guidelines recommend that these patients either (1) commence a statin because they have CKD and are at high CVD risk or (2) have management intensified as, despite prescription of a statin, they have a cholesterol result that is above current guideline recommendations.",
    'Ckd_Rec_InitiateAceArbBpOk' : 'This patient is at high CVD risk or has a coded diagnosis of hypertension. Patient is currently reaching their blood pressure targets, but commencement of ACE inhibitor or Angiotension receptor blocker should be considered if clinically appropriate.',
    'PrC_Rec_RepeatPSAOrderFT' : 'Prostate Cancer risk - Repeat PSA test and order Free-to-Total PSA recommended.',
    'PrC_Rec_FTPSAOnly' : 'Prostate Cancer risk – Free-to-Total PSA only recommended',
    'PrC_Rec_ReferToUrologist' : 'At risk of Prostate Cancer – referral to Urologist recommended.',
    'PrC_Rec_ReviewPatientsTest' : 'At-risk of Prostate Cancer - Review of PSA test result and Patient past history recommended.',
    'PltC_Rec_MaleRepeatPltCount': 'Raised platelet count. At risk of cancer (male): Repeat platelet count recommended. Review for symptoms, especially for lung, colorectal, gastro-oesophageal.',
    'PltC_Rec_FemaleRepeatPltCount': 'Raised platelet count. At risk of cancer (female): Repeat platelet count recommended. Review for symptoms, especially for lung, colorectal, gastro-oesophageal, endometrial or ovarian.',
    'PltC_Rec_MaleTwoConsecElevatedPltCount': 'Two raised platelet counts. At risk of cancer (male): Review for symptoms recommended - especially Lung, Colorectal, Gastro-oesophageal. Consider CXR and faecal occult blood test.',
    'PltC_Rec_FemaleTwoConsecElevatedPltCount': 'Two raised platelet counts. At risk of cancer (female): Review for symptoms recommended - especially Lung, Colorectal, Gastro-oesophageal, Ovarian or Endometrial cancer. Consider CXR and faecal occult blood test and/or CA-125. If visible haematuria, or symptoms of ovarian cancer consider a direct access to ultrasound scan',
    'PltC_Rec_ReviewPatientsTest': 'Invalid result for platelet count: Review test result/unit of measure and Patient past history recommended.',

  },
  'recommendedTestCondition' : {
    'Ckd_Rec_AtRisk': 'Ckd_Rec_CompletedHealthCheck',
    'Ckd_Rec_Possible': 'Ckd_Rec_Possible',
    'Ckd_Rec_CdCkd': 'Ckd_Rec_CdCkd',
    'Ckd_Rec_InitiateAceArb': 'Ckd_Rec_InitiateAceArb',
    'Ckd_Rec_IntensifyAceArb': 'Ckd_Rec_IntensifyAceArb',
    'Ckd_Rec_InitiateStatin': 'Ckd_Rec_InitiateStatin',
    'Ckd_Rec_IntensifyStatin': 'Ckd_Rec_IntensifyStatin',
    'Ckd_Rec_InitiateAceArbBpOk': 'Ckd_Rec_InitiateAceArbBpOk',
    'PrC_Rec_RepeatPSAOrderFT' : 'PrC_Rec_RepeatPSAOrderFT',
    'PrC_Rec_FTPSAOnly' : 'PrC_Rec_FTPSAOnly',
    'PrC_Rec_ReferToUrologist' : 'PrC_Rec_ReferToUrologist',
    'PrC_Rec_ReviewPatientsTest' : 'PrC_Rec_ReviewPatientsTest',
  },
  'recommendedTests': {
    'Ckd_Rec_Possible': {
      'title' : '',
      'components': {
        'EGFR<60': 'Consider repeating eGFR test within 3 months to confirm diagnosis of CKD',
        'ACR>=2.5Or3.5' : 'Consider repeating uACR test within 3 months to confirm diagnosis of CKD - as per kidney Australia guidelines - 2 out of 3 tests must be abnormal and the abnormal results are at least 3 months apart'
      }
    },
    'Ckd_Rec_CdCkd': {
      'title' : '',
      'duplicate': true,
      'components': {
        'default': 'Consider review of patient to confirm diagnosis of CKD'
      }
    },
    'Ckd_Rec_InitiateAceArb': {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider Initiation of ACEI or ARB.'
      }
    },
    'Ckd_Rec_IntensifyAceArb': {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider Intensification of ACEI or ARB dosage or initiation of another agent.'
      }
    },
    'Ckd_Rec_InitiateStatin': {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider Initiation of Lipid-lowering Therapy.'
      }
    },
    'Ckd_Rec_IntensifyStatin': {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider intensification of lipid-lowing therapy for CKD management'
      }
    },
    'Ckd_Rec_InitiateAceArbBpOk': {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider Initiation of ACEI or ARB.'
      }
    },
    'Ckd_Rec_CompletedHealthCheck': {
      'title' : 'The Following Kidney Health Check is recommended',
      'components': {
        'REQ_ACR_365': 'uACR test in 1 year',
        'REQ_EGFR_365': 'EGFR test in 1 year',
        'REQ_DBP_365': 'DBP test in 1 year',
        'REQ_SBP_365': 'SBP test in 1 year',
        'REQ_ACR_730': 'uACR test in 2 year',
        'REQ_EGFR_730': 'EGFR test in 2 year',
        'REQ_DBP_730': 'DBP test in 2 year',
        'REQ_SBP_730': 'SBP test in 2 year'
      }
    },
    'PrC_Rec_RepeatPSAOrderFT': {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Repeat PSA test and order Free-to-Total PSA recommended.'
      }
    },
    'PrC_Rec_FTPSAOnly': {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'At risk of Prostate Cancer – Free-to-Total PSA only recommended.'
      }
    },
    'PrC_Rec_ReferToUrologist': {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'At risk of Prostate Cancer – referral to Urologist recommended.'
      }
    },
    'PrC_Rec_ReviewPatientsTest': {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'At-risk of Prostate Cancer - Review of PSA test result and Patient past history recommended.'
      }
    },
    'PltC_Rec_MaleRepeatPltCount' : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Raised platelet count. At risk of cancer (male): Repeat platelet count recommended. Review for symptoms, especially for lung, colorectal, gastro-oesophageal.'
      }
    },
    'PltC_Rec_FemaleRepeatPltCount' : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Raised platelet count. At risk of cancer (female): Repeat platelet count recommended. Review for symptoms, especially for lung, colorectal, gastro-oesophageal, endometrial or ovarian.'
      }
    },
    'PltC_Rec_MaleTwoConsecElevatedPltCount' : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Two raised platelet counts. At risk of cancer (male): Review for symptoms recommended - especially Lung, Colorectal, Gastro-oesophageal. Consider CXR and faecal occult blood test.'      }
    },
    'PltC_Rec_FemaleTwoConsecElevatedPltCount' : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Two raised platelet counts. At risk of cancer (female): Review for symptoms recommended - especially Lung, Colorectal, Gastro-oesophageal, Ovarian or Endometrial cancer. Consider CXR and faecal occult blood test and/or CA-125. If visible haematuria, or symptoms of ovarian cancer consider a direct access to ultrasound scan'      }
    },
    'PltC_Rec_ReviewPatientsTest' : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Invalid result for platelet count: Review test result/unit of measure and Patient past history recommended.'
      }
    },
    'Dia_Rec_BP' : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Optimisation blood pressure recommended'
      }
    },
    'Dia_Rec_AtRisk' : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Recommend fasting blood glucose level or HbA1c testing'
      }
    }
  },
  'recommendedTestsById': {
    1: {
      'title' : 'The Following Kidney Health Check is recommended',
      'components': {
        'REQ_ACR_365': 'uACR test in 1 year',
        'REQ_EGFR_365': 'EGFR test in 1 year',
        'REQ_DBP_365': 'DBP test in 1 year',
        'REQ_SBP_365': 'SBP test in 1 year',
        'REQ_ACR_730': 'uACR test in 2 year',
        'REQ_EGFR_730': 'EGFR test in 2 year',
        'REQ_DBP_730': 'DBP test in 2 year',
        'REQ_SBP_730': 'SBP test in 2 year'
      }
    },
    2: {
      'title' : '',
      'components': {
        17: 'Consider repeating eGFR test within 3 months to confirm diagnosis of CKD',
        15 : 'Consider repeating uACR test within 3 months to confirm diagnosis of CKD - as per kidney Australia guidelines - 2 out of 3 tests must be abnormal and the abnormal results are at least 3 months apart'
      }
    },
    3: {
      'title' : '',
      'duplicate': true,
      'components': {
        'default': 'Consider review of patient to confirm diagnosis of CKD'
      }
    },
    4: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider Initiation of ACEI or ARB.'
      }
    },
    5: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider Intensification of ACEI or ARB dosage or initiation of another agent.'
      }
    },
    6: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider Initiation of Lipid-lowering Therapy.'
      }
    },
    7: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider intensification of lipid-lowing therapy for CKD management'
      }
    },
    8: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider Initiation of ACEI or ARB.'
      }
    },
    19: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Repeat PSA test and order Free-to-Total PSA recommended.'
      }
    },
    20: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'At risk of Prostate Cancer – Free-to-Total PSA only recommended.'
      }
    },
    21: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'At risk of Prostate Cancer – referral to Urologist recommended.'
      }
    },
    22: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'At-risk of Prostate Cancer - Review of PSA test result and Patient past history recommended.'
      }
    },
    24 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Raised platelet count. At risk of cancer (male): Repeat platelet count recommended. Review for symptoms, especially for lung, colorectal, gastro-oesophageal.'
      }
    },
    25 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Raised platelet count. At risk of cancer (female): Repeat platelet count recommended. Review for symptoms, especially for lung, colorectal, gastro-oesophageal, endometrial or ovarian.'
      }
    },
    26 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Two raised platelet counts. At risk of cancer (male): Review for symptoms recommended - especially Lung, Colorectal, Gastro-oesophageal. Consider CXR and faecal occult blood test.'      }
    },
    27 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Two raised platelet counts. At risk of cancer (female): Review for symptoms recommended - especially Lung, Colorectal, Gastro-oesophageal, Ovarian or Endometrial cancer. Consider CXR and faecal occult blood test and/or CA-125. If visible haematuria, or symptoms of ovarian cancer consider a direct access to ultrasound scan'      }
    },
    28 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Invalid result for platelet count: Review test result/unit of measure and Patient past history recommended.'
      }
    },
    4000 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Repeat blood count and order iron studies recommended. Review for gastrointestinal symptoms and consider other anaemia differential diagnosis.'
      }
    },
    4001 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider coeliac serology. If negative, consider upper and lower endoscopy according to guidelines. Consider other anaemia differential diagnosis. '
      }
    },
    4002 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider coeliac serology. If negative, consider upper and lower endoscopy according to guidelines. Consider other anaemia differential diagnosis. Consider management of iron deficiency anaemia.'
      }
    },
    4003 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'If visible haematuria or postmenopausal bleeding present, consider transvaginal ultrasound'
      }
    },
    6000 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider Heart Health Check'
      }
    },
    6001 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider use of SAMS tool to assess whether statin can be re-commenced at a low or intermittent dose to reduce CVD risk'
      }
    },    
    6002 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider initiation of antihypertensive therapy for CVD prevention'
      }
    },
    6003 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider initiation of statin for CVD primary prevention'
      }
    },
    6004 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Diagnosed with CVD but not currently on statin. Consider Initiation of statin'
      }
    },
    6005 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider intensification of lipid-lowing therapy for CVD management  '
      }
    },
    6006 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider initiation or intensification of antihypertensive therapy for management of CKD (BP not at target)'
      }
    },
    6008 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider initiation of anticoagulant therapy'
      }
    },
    5002 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Review of AD medication recommended: discuss WiserAD (deprescribing).'
      }
    },
    9004 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Optimisation blood pressure recommended'
      }
    },
    9005 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider commencement or intensification of a statin'
      }
    },
    9000 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Recommend fasting blood glucose level or HbA1c testing'
      }
    },
    9002: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Pathology consistent with Type 2 Diabetes. Review and code'
      }
    },
    9003: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Consider optimisation of glycaemia; HbA1c ≥7% (53mmol/mol)'
      }
    },
    9001: {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'Type 2 Diabetes likely, requires confirmatory testing with HbA1c or fasting blood glucose or oral glucose tolerance test, unless symptoms are present. If symptoms are present, consider recording diagnosis of type 2 diabetes'
      }
    },
    201 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'CovidCare: Vital signs emergency alert: emergency attention recommended.'
      }
    },
    202 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'CovidCare: Abnormal vital signs alert: re-check recommended.'
      }
    },
    203 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'CovidCare: Vital signs negative trend alert: re-check recommended.'
      }
    },
    204 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'CovidCare: Vital signs negative trend alert: emergency attention recommended.'
      }
    },
    205 : {
      'title': '',
      'duplicate': true,
      'components': {
        'default': 'CovidCare: Emotional wellbeing warning: medical attention recommended.'
      }
    }
  },
  "units" : {
    "uACR" : "mg/mmol",
    "eGFR" : "mL/min/1.73m2",
    "HbA1c" : "%",
    "SBP" : "mmHg",
    "DBP" : "mmHg",
    "TG" : "mmol/L",
    "TC" : "mmol/L",
    "LDL" : "mmol/L",
    "HDL" : "mmol/L",
    "CHOL" : "mmol/L",
    "BSL" : "mmol/L",
    "BSLF" : "mmol/L",
    "PSA" : "ng/ml",
    "ftPSA" : "%",
    "pltCount" : "x10*9/L"
  },

    //Cohort related names
    "codedCVDTickbox": "13",	
    "codedDiabetesTickbox": "12",
    "genderTickboxAll":"genderTickboxAll",
    "genderTickboxMale":"genderTickboxMale",
    "genderTickboxFemale":"genderTickboxFemale",
    "diseaseTickboxCkd":"diseaseTickboxCkd",
    "diseaseTickboxDiabetes":"diseaseTickboxDiabetes",
    "diseaseTickboxProstateCancer":"diseaseTickboxProstateCancer",
    "diseaseTickboxCancer":"diseaseTickboxCancer",
    "atRiskTickbox":"1",
    "possibleTickbox":"2",
    "codedDiagnosisTickbox":"3",
    "initiateStatinTickbox":"6",
    "intensifyStatinTickbox":"7",
    "initiateAceArbTickbox":"4",
    "intensifyAceArbTickbox":"5",
    "initiateAceArbBpInRangeTickbox":"8",
    "PrCRepeatPSAOrderFT": "19",
    "PrCFTPSAOnly": "20",
    "PrCRecReferToUrologist" : "21",
    "PrCRecReviewPatientsTest" : "22",
    "codedProstateCancer":"23",
    "PltCRecMaleRepeatPltCount": "24",
    "PltCRecFemaleRepeatPltCount": "25",
    "PltCRecMaleTwoConsecElevatedPltCount": "26",
    "PltCRecFemaleTwoConsecElevatedPltCount": "27",
    "PltCRecReviewPatientsTest": "28",
    "MentHRecReviewADMed": "5002",

    "COVCareRecVitSignEmergency": "201",
    "COVCareRecAbnormalVitSigns": "202",
    "COVCareRecNegVitSignsEmergency": "203",
    "COVCareRecNegVitSignsReCheck": "204",
    "COVCareRecEmotionalWarning": "205",
    "gastCancerAtrisk": "4000",
    "malegastCancerAtrisk": "4001",
    "femalegastCancerAtrisk": "4002",
    "endoCancerAtrisk": "4003",
    "codedGastEndoCancer": "4004",

    "Cvd_Rec_AtRiskNoCheck": "6000",
    "Cvd_Rec_HighRiskNoStatinAllergy":"6001",
    "Cvd_Rec_HighRiskNoBpMed": "6002",
    "Cvd_Rec_HighRiskNoStatin": "6003",
    "Cvd_Rec_CvdNoStatin": "6004",
    "Cvd_Rec_CvdOnStatinMissedLipidsTarget": "6005",
    "Cvd_Rec_CvdMissedBpTarget": "6006",
    "Cvd_Rec_AfibAndStrokeNoAnticoagulant":"6008",

    "Dia_Rec_BP":"9004",
    "Dia_Rec_AtRisk":"9000",

    //Action required API search names
    "cohortid":"cohortid", //url filter name
    "workflowStageId":"workflowStageId",//url filter name
    "webAPISearchCohortId":"WebAPISearchCohortId", //name used in the viewModel in the webapi
    "webAPIFilterWorkflowId":"WebAPIFilterWorkflowId",//name used in the viewModel in the webapi
    //NOTE: The names here are sent via webapi. For example, "No_Action" in webapi is No Action. TODO: improve this in the future.
    "cohortBargraphMapname":{
      "Action complete. Currently no recommendations.":"0",
      "To Be Reviewed":"1",
      "To Be Approved":"2",
      "To Be Recalled":"3",
      "Awaiting Consultation":"4",
      "Action In Progress":"5",
      "In Deferral":"6",
      "Defer Recommendation":"7",
    },
    //TODO: merge with the above
    "workflowStages":{
      "NoAction":"0",
      "ToBeReviewed":"1",
      "ToBeApproved":"2",
      "ToBeRecalled":"3",
      "AwaitingConsultation":"4",
      "ActionInProgress":"5",
      "InDeferral":"6",     
      "DeferRecommendation":"7",  
    },
    "diseaseIDs":{
      "IC3":"11",
      "UQRelease":"15",
    },
    "diseaseNamesToIDs":{
      "IC3 The Liver Health Trial":"11",
      "Eligible for RELEASE Trial":"15",
    },
    recentUserGridSettings:"RecentUserGridSettings",
    recentUserGridSettingsCreateCohort:"RecentUserGridSettingsCreateCohort",
    recentUserGridSettingsConsentManagement:"RecentUserGridSettingsConsentManagement",
    recentUserGridSettingsVersion: "v2",
    torchLimitedUserRolesReferenceID: 16,
};

export default Configs;
